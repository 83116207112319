import Navbar from "../components/Navbar";
import "../styles/profile.css";
import React, { useState, useEffect } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Sidebar from "../components/Sidebar";
import StorageManager from "../services/common/storageManager";

function Profile() {

  const storageManager = new StorageManager();
  const userInfos = storageManager.getUserInfos();


  const handleChange = (event) => {
    // const { name, value } = event.target;
    // setUser({ ...user, [name]: value });
  };

  const handleEdit = () => {
    //console.log(user);
  };

  return (
    <>
      <div className="profile-container">
        <Navbar />
          <div className="profile">
            <Sidebar />

            <div className="about">
              <div className="user-info-title">My Profile</div>
              <div className="infos">
                <div className="info">
                  <label className="about-label">
                    First Name
                  </label>
                  <input
                    className="about-textbox"
                    type="text"
                    name="name"
                    value={userInfos.name}
                    readOnly
                  />
                </div>

                <div className="info">
                  <label className="about-label">
                    Last Name
                  </label>
                  <input
                    className="about-textbox"
                    type="text"
                    name="lastName"
                    value={userInfos.lastName}
                    readOnly
                  />
                </div>

                <div className="info">
                  <label className="about-label">
                    Email
                  </label>
                  <input
                    className="about-textbox"
                    type="email"
                    name="email"
                    value={userInfos.email}
                    readOnly
                  />

                </div>

                <div className="info">
                  <label className="about-label">
                    Phone
                  </label>
                  <input
                    className="about-textbox"
                    type="text"
                    name="phone"
                    value={""}
                    onChange={handleChange}
                  />

                </div>
              </div>

              <button className="edit-button" onClick={() => handleEdit()}>
                Save changes
              </button>
            </div>
          </div>
        
      </div>
    </>
  );
}

export default Profile;
