/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "../styles/addresses.css";
import Sidebar from "../components/Sidebar";
import AddIcon from "@mui/icons-material/Add";
import AddressService from "../services/models/addressService";
import StorageManager from "../services/common/storageManager";
import TokenDecoder from "../services/common/tokenDecoder";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom"
import { toastrService } from "../services/common/toasterService";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

function Addresses() {

  const navigate = useNavigate()


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [deleteId, setDeleteId] = useState()

  const [addresses, setAddresses] = useState([]);

  const storageManager = new StorageManager();
  const userInfos = storageManager.getUserInfos();
  const tokenDecoder = new TokenDecoder();
  const userId = tokenDecoder.getUserId(storageManager.getToken().token);

  const addressService = new AddressService();

  const [address, setAddress] = useState({
    userId: userId,
    receiver: "",
    phoneNumber: "",
    title: "",
    city: "",
    country: "",
    district: "",
    neighbourhood: "",
    addressDetail: "",
    zipCode: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddress({ ...address, [name]: value });
  };

  const addAddress = async () => {
    try {
      await addressService.addAddress(address);
      handleClose()
      const response = await addressService.getAddressesByUserId(userId);
      setAddresses(response);
      toastrService.message("Address added succesfully!", {
        messageType: "success",
        position: "bottom-right",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateAddress = async (id) => {
    try {
      const updatedId = id;
      const updatedTitle = address.title;
      const updatedReceiver = address.receiver;
      const updatedPhoneNumber = address.phoneNumber;
      const updatedCity = address.city;
      const updatedCountry = address.country;
      const updatedDistrict = address.district;
      const updatedNeighbourhood = address.neighbourhood;
      const updatedAddressDetail = address.addressDetail;
      const updatedZipCode = address.zipCode;
      let newAddress = {
        id: updatedId,
        userId: userId,
        title: updatedTitle,
        city: updatedCity,
        country: updatedCountry,
        district: updatedDistrict,
        neighbourhood: updatedNeighbourhood,
        addressDetail: updatedAddressDetail,
        zipCode: updatedZipCode
      };
      console.log(newAddress);
      await addressService.updateAddress(newAddress);
      console.log(id);
      const updatedAddressIndex = addresses.findIndex(item => item.id === updatedId);
      addresses[updatedAddressIndex].title = updatedTitle;
      addresses[updatedAddressIndex].city = updatedCity;
      addresses[updatedAddressIndex].country = updatedCountry;
      addresses[updatedAddressIndex].district = updatedDistrict;
      addresses[updatedAddressIndex].addressDetail = updatedAddressDetail;
      addresses[updatedAddressIndex].zipCode = updatedZipCode;
      addresses[updatedAddressIndex].neighbourhood = updatedNeighbourhood;
      addresses[updatedAddressIndex].receiver = updatedReceiver;
      addresses[updatedAddressIndex].phoneNumber = updatedPhoneNumber
      toastrService.message("Address updated succesfully!", {
        messageType: "success",
        position: "bottom-right",
      });
      handleCloseEdit()
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (id) => {
    console.log(id)
    handleOpenDelete();
    setDeleteId(id);
  };

  const deleteAddress = async (id) => {
    try {
      await addressService.deleteAddress(id);
      const newAddresses = addresses.filter(item => item.id !== id);
      setAddresses(newAddresses);
      setDeleteId()
      handleCloseDelete()
      toastrService.message("Address deleted succesfully!", {
        messageType: "success",
        position: "bottom-right",
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await addressService.getAddressesByUserId(userId);
        setAddresses(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAddresses();
  }, []);

  const editAddress = async (id) => {
    handleOpenEdit()
    for (let index = 0; index < addresses.length; index++) {
      if (addresses[index].id === id) {
        setAddress({
          userId: userId,
          id: id,
          receiver: addresses[index].receiver,
          phoneNumber: addresses[index].phoneNumber,
          title: addresses[index].title,
          city: addresses[index].city,
          country: addresses[index].country,
          district: addresses[index].district,
          neighbourhood: addresses[index].neighbourhood,
          addressDetail: addresses[index].addressDetail,
          zipCode: addresses[index].zipCode
        });
      }
    }

  };

  const handleAddOpen = () => {
    setAddress({
      userId: userId,
      receiver: "",
      phoneNumber: "",
      title: "",
      city: "",
      country: "",
      district: "",
      neighbourhood: "",
      addressDetail: "",
      zipCode: ""
    })
    handleOpen()
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addAddress()
  };

  return (
    <div className='addresses-container'>
      <Navbar />
      <div className="profile">
        <Sidebar />
        <div className="main">

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add addresses
              </Typography>
              <div className="main">
                
                  <form className="form-container" onSubmit={handleSubmit}>
                    <input
                      className="form-input"
                      required
                      type="text"
                      name="title"
                      placeholder="Title"
                      value={address.title}
                      onChange={handleChange}
                    />

                    <input
                      className="form-input"
                      required
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      value={address.phoneNumber}
                      onChange={handleChange}
                    />

                    <input
                      className="form-input"
                      required
                      type="text"
                      name="receiver"
                      placeholder="Receiver"
                      value={address.receiver}
                      onChange={handleChange}
                    />

                    <input
                      className="form-input"
                      required
                      type="text"
                      name="country"
                      placeholder="Country"
                      value={address.country}
                      onChange={handleChange}
                    />

                    <input
                      className="form-input"
                      required
                      type="text"
                      name="city"
                      placeholder="City"
                      value={address.city}
                      onChange={handleChange}
                    />

                    <input
                      className="form-input"
                      required
                      type="text"
                      name="district"
                      placeholder="District"
                      value={address.district}
                      onChange={handleChange}
                    />

                    <input
                      className="form-input"
                      required
                      type="text"
                      name="neighbourhood"
                      placeholder="Neighbourhood"
                      value={address.neighbourhood}
                      onChange={handleChange}
                    />

                    <input
                      className="form-input"
                      required
                      type="text"
                      name="addressDetail"
                      placeholder="Address Detail"
                      value={address.addressDetail}
                      onChange={handleChange}
                    />

                    <input
                      className="form-input"
                      required
                      type="text"
                      name="zipCode"
                      placeholder="Zip Code"
                      value={address.zipCode}
                      onChange={handleChange}
                    />

                    <div className="modal-buttons">
                      <button
                        type="button"
                        className="close-address"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      <button className="save-address" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                
              </div>
            </Box>

          </Modal>

          <Modal
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit Address
              </Typography>
              <div className="main">
                <div className="form-container">
                  <input className="form-input" type="text" name="title" placeholder="Title" value={address.title} onChange={handleChange} />

                  <input className="form-input" type="text" name="phoneNumber" placeholder="Phone Number" value={address.phoneNumber} onChange={handleChange} />

                  <input className="form-input" type="text" name="receiver" placeholder="Receiver" value={address.receiver} onChange={handleChange} />

                  <input className="form-input" type="text" name="country" placeholder="Country" value={address.country} onChange={handleChange} />

                  <input className="form-input" type="text" name="city" placeholder="City" value={address.city} onChange={handleChange} />

                  <input className="form-input" type="text" name="district" placeholder="District" value={address.district} onChange={handleChange} />

                  <input className="form-input" type="text" name="neighbourhood" placeholder="Neighbourhood" value={address.neighbourhood} onChange={handleChange} />

                  <input className="form-input" type="text" name="addressDetail" placeholder="Address Detail" value={address.addressDetail} onChange={handleChange} />

                  <input className="form-input" type="text" name="zipCode" placeholder="Zip Code" value={address.zipCode} onChange={handleChange} />
                  <button className="save-address" onClick={() => updateAddress(address.id)}>Save</button>
                </div>
              </div>
            </Box>
          </Modal>

          <Modal
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: 'flex', textAlign: 'center' }}>
                Are you sure you want to delese this address?
              </Typography>
              <div className="modal-buttons">
                <button className="close-address" onClick={() => handleCloseDelete()}>Cancel</button>
                <button className="save-address" onClick={() => deleteAddress(deleteId)}>Delete</button>
              </div>
            </Box>
          </Modal>





          <div className='addresses'>
            <div className='addresses-title'>My Addresses <button className="add-address" onClick={() => handleAddOpen()}><AddIcon />Add New Address</button></div>
            <div className='cards-container'>
              {addresses.map(address => (
                <div key={address.id} className='address-cards'>
                  <div className='address-title'>{address.title}</div>
                  <div className='address-text'>{address.country}</div>
                  <div className='address-text'>{address.city}/{address.neighbourhood}</div>
                  <div className='address-text'>{address.addressDetail}</div>
                  <div className='address-text'>{address.receiver}</div>
                  <div className='address-text'>{address.phoneNumber}</div>
                  <div className='card-buttons'>
                    <button id='delete-button' onClick={() => handleDelete(address.id)}> Delete</button>
                    <button id='edit-button' onClick={() => editAddress(address.id)}>Edit</button>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Addresses;