import {
  HttpClientService,
  RequestParameters,
} from "../../services/common/httpClientSevice";

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const httpClient = new HttpClientService(baseUrl);

export default class UserImageService {
  async getImagesByUserId(userId, pageIndex, pageSize) {
    const requestParameters = new RequestParameters(
      "userImages",
      "GetDynamic",
      `PageIndex=${pageIndex}&PageSize=${pageSize}`
    );
    const body = {
      filter: {
        field: "userId",
        operator: "eq",
        value: userId
      }
    };
    return await httpClient.post(requestParameters, body);
  }

  async addImage(userImage) {
    console.log(userImage)
    const requestParameters = new RequestParameters("userImages");
    return await httpClient.post(requestParameters, userImage);
  }

  async deleteImage(id) {
    const requestParameters = new RequestParameters(`userImages/${id}`);
    return await httpClient.patch(requestParameters);
  }

  async uploadImage(formData){
    const requestParameters= new RequestParameters("userImages","upload");
    return await httpClient.post(requestParameters,formData);
  }

  async generateImage(prompt,userId){
    const requestParameters= new RequestParameters("userImages","generateAndUpload");
    return await httpClient.post(requestParameters,{prompt:prompt,quantity:1,size:"",userId:userId});
  }
}
