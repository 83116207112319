import axios from "axios";
import { toastrService } from "./toasterService";
import StorageManager from "./storageManager";

const axiosInstance = axios.create();

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const storageManager = new StorageManager();
    const accessToken = storageManager.getToken();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    handleError(error.response?.data);
    return Promise.reject(error);
  }
);

function handleError(error) {
  let message = error?.detail ?? "Beklenmeyen bir hata oluştu!";

  switch (error?.status) {
    case 401:
      toastrService.message(message, {
        messageType: "error",
        position: "bottom-right",
      });
      break;
    case 400:
      toastrService.message(message, {
        messageType: "error",
        position: "bottom-right",
      });
      break;
    case 500:
      toastrService.message(message, {
        messageType: "error",
        position: "bottom-right",
      });
      break;
    case 404:
      toastrService.message(message, {
        messageType: "error",
        position: "bottom-right",
      });
      break;
    default:
      toastrService.message(message, {
        messageType: "error",
        position: "bottom-right",
      });
      break;
  }
}

export default axiosInstance;
