import {
  HttpClientService,
  RequestParameters,
} from "../../services/common/httpClientSevice";

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const httpClient = new HttpClientService(baseUrl);

export default class UserService {
  async getUsers(pageIndex, pageSize) {
    const requestParameters = new RequestParameters(
      "users",
      null,
      `PageIndex=${pageIndex}&PageSize=${pageSize}`
    );
    return await httpClient.get(requestParameters);
  }

  async getUserById(id) {
    const requestParameters = new RequestParameters(`users/${id}`);
    return await httpClient.get(requestParameters);
  }
  
  async getUserByEmail(email) {
    const body = {
      filter: {
        field: "email",
        operator: "eq",
        value: email,
      },
    };
    const requestParameters = new RequestParameters(
      "users",
      "GetDynamic?PageIndex=0&PageSize=1"
    );
    return await httpClient.post(requestParameters, body);
  }
}
