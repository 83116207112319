// App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Designs from "./pages/Designs";
import Orders from "./pages/Orders";
import Design from "./pages/Design";
import Cart from "./pages/Cart";
import Addresses from "./pages/Addresses";
import Products from "./pages/Products";
import AdminRoutes from "./admin/routes/AdminRouter"; // Import the new AdminRoutes
import { AdminLogin } from "./admin/pages/AdminLogin";
import { checkIsAuth } from "./redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import Product from "./pages/Product";
import OrderDetails from "./pages/OrderDetails";
import Pricing from "./pages/Pricing";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/profile" element={<UserRoute element={<Profile/>}/>} />
        <Route path="/profile/designs" element={<UserRoute element={<Designs/>}/>} />
        <Route path="/profile/products" element={<UserRoute element={<Product/>}/>} />
        <Route path="/profile/orders" element={<UserRoute element={<Orders/>}/>} />
        <Route path="/profile/addresses" element={<UserRoute element={<Addresses/>}/>} />
        <Route path="/profile/orders/:orderId" element={<UserRoute element={<OrderDetails/>}/>} />
        <Route path="/design/:id" element={<UserRoute element={<Design/>}/>} />
        <Route path="/cart" element={<UserRoute element={<Cart/>}/> } />
        <Route path="/products" element={<Products />} />
        <Route path="/login" element={<AdminLogin />} />
        <Route path="/admin/*" element={<AdminRoutes />} />
      </Routes>
    </Router>
  );
}

export default App;

// eslint-disable-next-line react/prop-types
const UserRoute = ({ element }) => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(checkIsAuth());
  }, [isAuth,location]);


  if (isLoading) return <div>Loading...</div>;

  if (!isLoading) {
    if (!isAuth) 
      return <Navigate to="/" state={{ from: location }} replace />;

    return element;
  }
};
