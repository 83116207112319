import { CHECK_IS_AUTH } from "../actions/authActions";
import { isAuth, isLoading } from "../initialValues/authItems";
import StorageManager from "../../services/common/storageManager";
import TokenDecoder from "../../services/common/tokenDecoder";

const initialState = {
  isAuth: isAuth,
  isLoading: isLoading,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_IS_AUTH: {
      const storageManager = new StorageManager();
      const userInfos = storageManager.getUserInfos();
      const token = storageManager.getToken();

      const tokenDecoder= new TokenDecoder();
      const isVerify=tokenDecoder.verifyJWT(token?.token);

      if(!isVerify){
        storageManager.removeToken();
        storageManager.removeUserInfos();
      }

      return {
        ...state,
        isAuth: userInfos && isVerify ? true : false,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}
