import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Box } from "@mui/material";
import BackgroundBox from "../components/BackgroundBox";

const UserDetail = () => {
  const email = "user@example.com";

  // Sample data for the tables
  const drafts = [
    { id: 1, title: "Draft 1", date: "2024-07-30" },
    { id: 2, title: "Draft 2", date: "2024-07-29" },
  ];

  const products = [
    { id: 1, name: "Product 1", price: 100 },
    { id: 2, name: "Product 2", price: 150 },
  ];

  const orders = [
    { id: 1, orderNumber: "ORD001", status: "Shipped" },
    { id: 2, orderNumber: "ORD002", status: "Pending" },
  ];

  const draftColumns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "title", headerName: "Title", width: 150 },
    { field: "date", headerName: "Date", width: 150 },
  ];

  const productColumns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "price", headerName: "Price", width: 150 },
  ];

  const orderColumns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "orderNumber", headerName: "Order Number", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
  ];

  return (
    <div>
      <Typography variant="h3" gutterBottom sx={{ color: "grey.300" }}>
        User Details
      </Typography>
      <BackgroundBox>
        <Box mt={4}>
          <Typography variant="h6" mb={2}>
            {email}
          </Typography>

          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              Drafts
            </Typography>
            <DataGrid
              rows={drafts}
              columns={draftColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              autoHeight
            />
          </Box>

          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              Products
            </Typography>
            <DataGrid
              rows={products}
              columns={productColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              autoHeight
            />
          </Box>

          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              Orders
            </Typography>
            <DataGrid
              rows={orders}
              columns={orderColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              autoHeight
            />
          </Box>
        </Box>
      </BackgroundBox>
    </div>
  );
};

export default UserDetail;
