import React from 'react'
import "../styles/footer.css"
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Footer() {
    return (
        <div className='footer'>

            <div className='policy'>
                <a className='copyright-link'>Privacy Policy</a> | <a className='copyright-link'>Terms</a>
            </div>

            <div className='copyright'>
                © 2024 All Rights Reserved, Lumino Design
            </div>

            <div className='socials'>
                <a><FacebookIcon /></a>
                <a><InstagramIcon /></a>
                <a><XIcon /></a>
                <a><LinkedInIcon /></a>
            </div>
        </div>
    )
}
