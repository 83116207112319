import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { createStore } from "./redux/configureStore";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const store = createStore();

const rootElement = document.getElementById("root");
if (rootElement === null) {
  throw Error("rootElement is null");
}

const root = ReactDOM.createRoot(rootElement);
root.render(
  <GoogleOAuthProvider clientId="657242746922-oc5o0of6d70isvmf678milta34n960bu.apps.googleusercontent.com">
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </GoogleOAuthProvider>
);
