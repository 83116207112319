import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  CardActions,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Carousel from "react-material-ui-carousel";
import StorageManager from "../services/common/storageManager";
import TokenDecoder from "../services/common/tokenDecoder";
import { useSelector } from "react-redux";
import AddToCartButton from "./AddToCartButton";

export default function ProductCard({ product }) {
  const BASE_IMG_URL = process.env.REACT_APP_IMAGES_BASE_URL;

  const isAuth = useSelector((state) => state.auth.isAuth);

  const storageManager = new StorageManager();
  const tokenDecoder = new TokenDecoder();
  var userId = "guest";

  if (isAuth) userId = tokenDecoder.getUserId(storageManager.getToken().token);

  return (
    <Card className="product-card" sx={{ maxWidth: 345, margin: "auto", backgroundColor:'rgba(167, 167, 167, 0.178);'}}>
      <Carousel
        className="card-image-carousel"
        indicators={false} // İsteğe bağlı: indikatörleri kapat
        animation="slide" // Slide animasyonu
        interval={3000} // Otomatik geçiş süresi
        autoPlay={false}
        height={250}
      >
        <CardMedia
          component="img"
          src={`${BASE_IMG_URL}${product.frontImagePath}` || "/logo.png"}
          alt="Front Image"
          sx={{
            width: "250px",
            height: "200px",
            objectFit: "contain",
            borderRadius: "4px",
            marginTop: "25px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <CardMedia
          component="img"
          src={`${BASE_IMG_URL}${product.backImagePath}` || "/logo.png"}
          alt="Back Image"
          sx={{
            width: "250px",
            height: "200px",
            objectFit: "contain",
            borderRadius: "4px",
            marginTop: "25px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </Carousel>

      {/* Ürün bilgileri */}
      <CardContent>
        <Typography variant="h6" component="div" style={{color:'whitesmoke'}}>
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{color:'whitesmoke'}}>
          {product.price}₺
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Typography variant="body2" style={{color:'whitesmoke'}}>{product.likeCount} Likes</Typography>
          <IconButton aria-label="like">
            <FavoriteIcon color="error" />
          </IconButton>
        </Box>
      </CardContent>

      {/* Add to Cart Butonu */}
      <CardActions sx={{ justifyContent: "center" }}>
        {isAuth ? (
          <AddToCartButton productId={product.id} userId={userId} />
        ) : (
          ""
        )}
      </CardActions>
    </Card>
  );
}
