import React, { useEffect } from "react";
import "../../styles/login.css";
import { useDispatch, useSelector } from "react-redux";
import "../styles/AdminLogin.css";
import { useLocation, useNavigate } from "react-router-dom";
import Login from "../../components/Login";
import { checkIsAuth } from "../../redux/actions/authActions";

export const AdminLogin = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(checkIsAuth());
    if (isAuth) {
      //console.log("location.state?.from: ",location.state.from.pathname);
      navigate(location.state?.from?.pathname || "/admin", { replace: true });
    }
  }, [isAuth]);

  return (
    <div className="login-container">
      <h1>Admin Login</h1>
      <p className="authorization-text">
        You must have the necessary authorizations to be directed to the
        relevant page. If you do not have the necessary authorizations, you will
        log in and be directed to the home page.
      </p>
      <Login />
    </div>
  );
};
