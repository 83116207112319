// admin/routes/AdminRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users";
import UserDetail from "../pages/UserDetail";
import Drafts from "../pages/Drafts";
import ProductsAdmin from "../pages/Products";
import ProductDetail from "../pages/ProductDetail";
import OrdersAdmin from "../pages/Orders";
import { Settings } from "../pages/Settings";
import PrivateRoute from "./PrivateRoute";

const AdminRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PrivateRoute
          element={<AdminLayout />}
          //roles={["adminDeneme"]}
        />
      }
    >
      <Route
        index
        element={<PrivateRoute element={<Dashboard />} /*roles={["deneme"]}*/ />}
      />
      <Route
        path="users"
        element={<PrivateRoute element={<Users />} roles={[]} />}
      />
      <Route
        path="user/:id"
        element={<PrivateRoute element={<UserDetail />} />}
        roles={[]}
      />
      <Route
        path="drafts"
        element={<PrivateRoute element={<Drafts />} roles={[]} />}
      />
      <Route
        path="products"
        element={<PrivateRoute element={<ProductsAdmin />} roles={[]} />}
      />
      <Route
        path="product/:productId"
        element={<PrivateRoute element={<ProductDetail />} roles={[]} />}
      />
      <Route
        path="orders"
        element={<PrivateRoute element={<OrdersAdmin />} roles={[]} />}
      />
      <Route
        path="settings"
        element={<PrivateRoute element={<Settings />} /*roles={["settings"]}*/ />}
      />
    </Route>
  </Routes>
);

export default AdminRoutes;
