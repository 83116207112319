import Navbar from "../components/Navbar";
import "../styles/orders.css";
import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import OrderService from "../services/models/orderService";
import StorageManager from "../services/common/storageManager";
import TokenDecoder from "../services/common/tokenDecoder";
import BasketService from "../services/models/basketService";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Carousel from "react-material-ui-carousel";
import { useNavigate } from 'react-router-dom';



function Orders() {

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const BASE_IMG_URL = process.env.REACT_APP_IMAGES_BASE_URL;

  const [orders, setOrders] = useState([])
  const [basketItems, setBasketItems] = useState([])

  const orderService = new OrderService();
  const basketService = new BasketService();
  const storageManager = new StorageManager();
  const tokenDecoder = new TokenDecoder();
  const userId = tokenDecoder.getUserId(storageManager.getToken().token);

  const getUserOrders = async () => {
    const res = await orderService.getUserOrders(userId, 0, 50);
    setOrders(res.items)
  }

  const getOrderDetails = async (basketId) => {
    const basketItemsResponse = await basketService.getBasketItemsByBasketId(basketId)
    setBasketItems(basketItemsResponse.items)
    console.log(basketItems)
  }

  useEffect(() => {
    getUserOrders();
  }, [])

  return (
    <div className="orders-container">
      <Navbar />

      <div className="profile">
        <Sidebar />
        <div className="main">
          <h3>Orders</h3>
          <div className="orders">
            {orders.length > 0 ? (
              orders.map((order) => (
                <div className="order" key={order.id}>
                  <div className="order-info">
                    <div className="order-list">
                      <div className="order-title">Order ID</div>
                      <div className="order-id">{order.id}</div>
                    </div>
                    <div className="order-list">
                      <div className="order-title">Order Date</div>
                      <div>{order.createdDate.slice(0, 10) + " / " + order.createdDate.slice(11, 16)}</div>
                    </div>
                    <div className="order-list">
                      <div className="order-title">Order Status</div>
                      <div>{order.orderStatus}</div>
                    </div>
                    <div className="order-list">
                      <div className="order-title">Buyer</div>
                      <div className="buyer">{order.email}</div>
                    </div>
                    <div className="order-list">
                      <div className="order-title">Address</div>
                      <div className="address-info">{order.addressTitle}</div>
                    </div>
                  </div>

                  <button
                    className="order-btn"
                    onClick={() => navigate(`/profile/orders/${order.id}`, { state: { order } })}
                  >
                    Details
                  </button>
                </div>
              )))
              : (
                <div>
                  There's no order
                </div>
              )
            }


          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="order-detail-modal-box">
              <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: 'center' }}>
                Order Details
              </Typography>
              <div className="order-details">
                {basketItems.map((basketItem, index) =>
                  <div className="basket-item" key={index}>
                    <div className="basket-item-info">
                      <p>Product Name:</p>
                      <p>{basketItem.productName}</p>
                    </div>

                    <div className="basket-item-info">
                      <p>Quantity:</p>
                      <p>{basketItem.quantity}</p>
                    </div>

                    <div className="basket-item-info">
                      <p>Price:</p>
                      <p>{basketItem.price}</p>
                    </div>

                    <Carousel className="card-image-carosuel" autoPlay={false}>
                      <img
                        src={BASE_IMG_URL + basketItem.frontImagePath || "../../public/logo.png"}
                        alt={""}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "contain",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          marginTop: "25px",
                        }}
                      />
                      <img
                        src={BASE_IMG_URL + basketItem.backImagePath || "../../public/logo.png"}
                        alt={""}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "contain",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          marginTop: "25px",
                        }}
                      />
                    </Carousel>
                  </div>
                )}

              </div>
            </Box>
          </Modal>
        </div>
      </div>

    </div>
  );
}

export default Orders;