import { HttpClientService, RequestParameters } from "../../services/common/httpClientSevice"; 

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const httpClient = new HttpClientService(baseUrl);

export default class DraftImageService{
  async addDraftImages(draftImages){
    const requestParameters= new RequestParameters("draftImages","upload");
    return await httpClient.post(requestParameters,draftImages);
  }

  async updateDraftImage(draftImage){
    console.log("draftImage: ",draftImage);
    const requestParameters= new RequestParameters("draftImages","UpdateDraftImages");
    return await httpClient.put(requestParameters,draftImage);
  }

  async getDraftImagesByUserId(userId){
    const requestParameters= new RequestParameters("draftImages",`GetByUserId/${userId}`);
    return await httpClient.get(requestParameters);
  }

  async getDraftImagesByDraftId(draftId){
    const requestParameters= new RequestParameters("draftImages",`GetByDraftId/${draftId}`);
    return await httpClient.get(requestParameters);
  }
}