import { HttpClientService, RequestParameters } from "../../services/common/httpClientSevice"; 

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const httpClient = new HttpClientService(baseUrl);

export default class AddressService{
  async addAddress(address){
    const requestParameters= new RequestParameters("addresses");
    return await httpClient.post(requestParameters,address);
  }

  async updateAddress(address){
    const requestParameters= new RequestParameters("addresses");
    return await httpClient.put(requestParameters,address);
  }

  async deleteAddress(id){
    const requestParameters= new RequestParameters("addresses");
    return await httpClient.delete(requestParameters,id);
  }

  async getAddressesByUserId(userId){
    const requestParameters= new RequestParameters("addresses");
    return await httpClient.get(requestParameters,userId);
  }
}