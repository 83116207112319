import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import OrderService from "../../services/models/orderService";
import { Typography } from "@mui/material";
import BackgroundBox from "../components/BackgroundBox";

const columns = [
  { field: "id", headerName: "ID", width: 150 },
  { field: "basketId", headerName: "Basket ID", width: 180 },
  {
    field: "isCompleted",
    headerName: "Is Completed",
    width: 180,
    type: "boolean",
  },
  {
    field: "isCanceled",
    headerName: "Is Canceled",
    width: 180,
    type: "boolean",
  },
];

const Orders = () => {
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState(null);

  const orderService = new OrderService();

  const fetchData = async (pageIndex, pageSize) => {
    try {
      const response = await orderService.getOrders(pageIndex, pageSize);
      setRows(response.items);
      setTotalCount(response.count); // Total count for pagination
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err);
    }
  };

  useEffect(() => {
    fetchData(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  if (error) return <div>Error: {error.message}</div>;
  if (!rows.length) return <div>Loading...</div>;

  return (
    <div>
      <Typography variant="h3" gutterBottom sx={{ color: "grey.300" }}>
        Orders
      </Typography>
      <BackgroundBox>
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 15]}
            pagination
            paginationMode="server"
            rowCount={totalCount}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
          />
        </div>
      </BackgroundBox>
    </div>
  );
};

export default Orders;
