import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import ProductService from "../../services/models/productService";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import BackgroundBox from "../components/BackgroundBox";
import "../styles/products.css"

const columns = [
  { field: "id", headerName: "ID", width: 150 },
  { field: "draftId", headerName: "Draft ID", width: 180 },
  { field: "likeCount", headerName: "Like Count", width: 180 },
  { field: "name", headerName: "Name", width: 250 },
  { field: "frontImagePath", headerName: "Front Image Path", width: 250 },
  { field: "backImagePath", headerName: "Back Image Path", width: 250 },
  { field: "price", headerName: "Price", width: 180, type: "number" },
  { field: "isPrivate", headerName: "Is Private", width: 150, type: "boolean" },
];

const Products = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const productService = new ProductService();

  const fetchData = async (pageIndex, pageSize) => {
    try {
      const response = await productService.getProducts(pageIndex, pageSize);
      setRows(response.items);
      setTotalCount(response.count); // Total count for pagination
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err);
    }
  };



  useEffect(() => {
    fetchData(paginationModel.page, paginationModel.pageSize);
  }, [paginationModel.page, paginationModel.pageSize]);

  const goToProductDetail = (params) => {
    navigate(`/admin/product/${params.row.id}`);
  };



  if (error) return <div>Error: {error.message}</div>;
  if (!rows.length) return <div>Loading...</div>;



  //console.log(rows)
  //console.log(totalCount)
  //console.log(paginationModel.page, paginationModel.pageSize)

  return (
    <div className="products-container">
      <Typography variant="h3" gutterBottom sx={{ color: "black.300" }}>
        Products
      </Typography>
        <div className="data-grid">
          <DataGrid
            rows={rows}
            columns={columns}
            paginationModel={paginationModel}
            pageSizeOptions={[5, 10, 25]}
            onPaginationModelChange={setPaginationModel}
            paginationMode="server"
            rowCount={totalCount}
            onRowDoubleClick={goToProductDetail}
          />
        </div>
    </div>
  );
};

export default Products;
