import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import { useLocation, useParams } from 'react-router-dom';
import '../styles/orderDetails.css';
import Navbar from '../components/Navbar';
import BasketService from '../services/models/basketService';
import Carousel from 'react-material-ui-carousel';

export default function OrderDetails() {

  const location = useLocation();
  const { orderId } = useParams();
  const order = location.state?.order;
  const [basketItems, setBasketItems] = useState([]);
  const basketService = new BasketService();
  const BASE_IMG_URL = process.env.REACT_APP_IMAGES_BASE_URL;

  // Fetch basket items when the component loads
  useEffect(() => {
    const fetchBasketItems = async () => {
      if (order?.basketId) {
        try {
          const basketItemsResponse = await basketService.getBasketItemsByBasketId(order.basketId);
          setBasketItems(basketItemsResponse);
          console.log(basketItemsResponse); // Log the response
        } catch (error) {
          console.error('Failed to fetch basket items', error);
        }
      }
    };

    fetchBasketItems();
  }, [order]);

  console.log(order);

  if (!order) {
    return <div>No order details found!</div>;
  }

  return (
    <div className='order-details-container'>
      <Navbar />
      <div className='order-details'>
        <Sidebar />

        <div className='order-details-detail'>

          <div className="order-detail-info">
            <div className="order-detail-list">
              <div className="order-detail-title">Order ID</div>
              <div className="order-detail-id">{order.id}</div>
            </div>
            <div className="order-detail-list">
              <div className="order-detail-title">Order Date</div>
              <div>{order.createdDate.slice(0, 10) + " / " + order.createdDate.slice(11, 16)}</div>
            </div>
            <div className="order-detail-list">
              <div className="order-detail-title">Order Status</div>
              <div>{order.orderStatus}</div>
            </div>
            <div className="order-detail-list">
              <div className="order-detail-title">Buyer</div>
              <div className="order-detail-buyer">{order.email}</div>
            </div>
            <div className="order-detail-list">
              <div className="order-detail-title">Address</div>
              <div className="detail-address-info">{order.addressTitle}</div>
            </div>
          </div>

          <div className='detail-basket-items'>
            {basketItems.map((basketItem, index) =>
              <div className="basket-item-detail" key={index}>
                <Carousel className="detail-card-image-carosuel" autoPlay={false}>
                  <img
                    src={BASE_IMG_URL + basketItem.frontImagePath || "../../public/logo.png"}
                    alt={""}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "contain",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginTop: "25px",
                    }}
                  />
                  <img
                    src={BASE_IMG_URL + basketItem.backImagePath || "../../public/logo.png"}
                    alt={""}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "contain",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginTop: "25px",
                    }}
                  />
                </Carousel>

                <div className="basket-item-info">
                  <p>Product Name:</p>
                  <p>{basketItem.productName}</p>
                </div>

                <div className="basket-item-info">
                  <p>Quantity:</p>
                  <p>{basketItem.quantity}</p>
                </div>

                <div className="basket-item-info">
                  <p>Price:</p>
                  <p>{basketItem.price}</p>
                </div>


              </div>
            )}
          </div>
        </div>

      </div>
    </div>
  );
}
