import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import Navbar from "../components/Navbar";
import ProductCard from "../components/ProductCard";
import ProductService from "../services/models/productService";

function ProductPage() {
  const [filter, setFilter] = useState(""); 
  const [products, setProducts] = useState([]); // Boş dizi olarak başlatıyoruz

  const productService = new ProductService();

  const getAllProducts = async () => {
    try {
      const res = await productService.getProducts(0, 50);
      console.log(res);
      setProducts(res.items || []); // null ya da undefined durumunda boş bir dizi atanır
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts([]); // Hata durumunda da boş bir dizi atanır
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <Navbar />
      <Box display="flex" p={2}>
        {/* Filtreler Kısmı */}
        <Box width="20%" p={2} mr={2} sx={{ borderRight: "1px solid #ddd", color:'whitesmoke' }}>
          <Typography variant="h6" gutterBottom>
            <FilterListIcon /> Filtreler
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel style={{color:'whitesmoke'}} id="filter-label">Fiyat</InputLabel>
            <Select
              labelId="filter-label"
              id="filter-select"
              value={filter}
              label="Fiyat"
              onChange={handleFilterChange}
              style={{color:'whitesmoke'}}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={100}>100-200</MenuItem>
              <MenuItem value={200}>200-300</MenuItem>
              <MenuItem value={300}>300-400</MenuItem>
            </Select>
          </FormControl>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body2">
            Diğer filtreler burada olabilir.
          </Typography>
        </Box>

        {/* Ürünler Kısmı */}
        <Box width="80%" p={2}>
          <Grid container spacing={2}>
            {products.length > 0 ? (
              products.map((product) => (
                <Grid item xs={12} sm={6} md={3} key={product.id}>
                  <ProductCard product={product} />
                </Grid>
              ))
            ) : (
              <Typography variant="body1">Ürün bulunamadı.</Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default ProductPage;
