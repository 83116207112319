import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import BackgroundBox from "../components/BackgroundBox";
import AuthSettings from "../components/AuthSettings";
import "../styles/settings.css"

export const Settings = () => {
  return (
    <div className="settings-container">
      <Typography
        variant="h3"
        gutterBottom
        sx={{ color: "black.300"}}
      >
        Settings
      </Typography>
      <BackgroundBox>
        <div className="settings-accordion">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                backgroundColor: "#f5f5f5",
                borderBottom: "2px solid #ccc",
              }}
            >
              <Typography>Auth</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: "#f0f0f0" }}>
              <AuthSettings />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{
                backgroundColor: "#f5f5f5",
                borderBottom: "2px solid #ccc",
              }}
            >
              <Typography>Section 2</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: "#f0f0f0" }}>
              <Typography>This is the content for section 2.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              sx={{
                backgroundColor: "#f5f5f5",
                borderBottom: "2px solid #ccc",
              }}
            >
              <Typography>Section 3</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: "#f0f0f0" }}>
              <Typography>This is the content for section 3.</Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </BackgroundBox>
    </div>
  );
};
