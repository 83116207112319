const USER_INFO = "user_info";
const TOKEN = "token";

export default class StorageManager {

  setUserInfos = (userInfos) => localStorage.setItem(USER_INFO, this.#objectToString(userInfos));
  getUserInfos = () => this.#stringToObject(localStorage.getItem(USER_INFO));  
  removeUserInfos = () => localStorage.removeItem(USER_INFO);

  setToken = (token) => localStorage.setItem(TOKEN, this.#objectToString(token));
  getToken = () => this.#stringToObject(localStorage.getItem(TOKEN));
  removeToken = () => localStorage.removeItem(TOKEN);

  #objectToString=(object)=> JSON.stringify(object);
  #stringToObject = (string)=> JSON.parse(string);
}


