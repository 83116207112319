import { isEmpty } from "lodash";
import { jwtDecode } from "jwt-decode";

export default class TokenDecoder {
  getUserInfos(credentialResponse) {
    const decodedResponse = this.#decodeGoogleResponse(credentialResponse);

    const userInfos = {
      name: decodedResponse.name,
      lastName: decodedResponse.family_name,
      email: decodedResponse.email,
      picture: decodedResponse.picture,
    };
    return userInfos;
  }

  getGoogleLoginRequest(credentialResponse) {
    const decodedResponse = this.#decodeGoogleResponse(credentialResponse);

    const request = {
      IdToken: credentialResponse.credential,
      Email: decodedResponse.email,
      Name: decodedResponse.name,
      FirstName: decodedResponse.given_name,
      LastName: decodedResponse.family_name ?? "UNSPECIFIED",
    };
    return request;
  }

  #decodeGoogleResponse(credentialResponse) {
    if (!isEmpty(credentialResponse.credential))
      return jwtDecode(credentialResponse.credential);

    throw new Error("Credential Response is empty");
  }

  verifyJWT(accessToken) {
    if (!accessToken) {
      return false;
    }

    try {
      const decodedToken = jwtDecode(accessToken);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.nbf && currentTime < decodedToken.nbf) return false;

      if (decodedToken.exp && currentTime > decodedToken.exp) return false;

      return true;
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }
  }

  getRoles(accessToken) {
    const decodedToken = this.#decodeAccessToken(accessToken);
    if(!decodedToken) return null;
    const roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    return roles;
  }

  #decodeAccessToken(accessToken) {
    if (!isEmpty(accessToken)) return jwtDecode(accessToken);

    // throw new Error("Access Token is empty");
    return null;
  }

  getUserId(accessToken){
    const decodedToken = this.#decodeAccessToken(accessToken);
    if(!decodedToken) return null;
    const userId = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
    return userId;
  }
}
