import { HttpClientService, RequestParameters } from "../../services/common/httpClientSevice"; 

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const httpClient = new HttpClientService(baseUrl);

export default class DraftService{
  async getDraftsDynamic(pageIndex,pageSize){
    const requestParameters= new RequestParameters("drafts","GetDynamic",`PageIndex=${pageIndex}&PageSize=${pageSize}`);
    return await httpClient.post(requestParameters,{});
  }

  async getDraftById(id){
    const requestParameters= new RequestParameters(`drafts/${id}`);
    return await httpClient.get(requestParameters);
  }

  async addDraft(draft){
    const requestParameters= new RequestParameters("drafts");
    return await httpClient.post(requestParameters,draft);
  }

  async updateDraft(draft){
    const requestParameters= new RequestParameters("drafts");
    return await httpClient.put(requestParameters,draft);
  }

  async deleteDraft(id){
    const requestParameters= new RequestParameters("drafts");
    return await httpClient.delete(requestParameters,id);
  }
}