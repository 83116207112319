import React from "react";
import "../styles/home.css";
import Navbar from "../components/Navbar";
import DrawIcon from "@mui/icons-material/Draw";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Footer from "../components/Footer";

function Home() {

  return (

    <div className='home-container'>
      <Navbar />
      <div className='presentation'>
        <div className='presentation-main'>
          <div className='left'>
            <p className='text'>
              Unleash your creativity by designing, customizing, and printing your own unique products
            </p>
            <p className='sub-text'>
              Elevate your style with personalized shirt designs, put your spin on essentials and order effortlessly from Lumino Design.
            </p>
          </div>

          <div className="right">
            <div className="btn"><a href="/profile/designs" >Start Designing</a></div>
          </div>

        </div>
        <img src="/shirt.png"/>
      </div>

      <div className='titles'>
        <div className='title'><div><DrawIcon /></div>Simple to craft and personalize</div>
        <div className='title'><div><AllInclusiveIcon /></div>Environmentally conscious and local manufacturing</div>
        <div className='title'><div><LocalShippingIcon /></div>Fast and free shipping</div>
        <div className='title'><div><SentimentSatisfiedAltIcon /></div>A guarantee of customer happiness</div>
      </div>
      <h1 className='cards-title'>Start create your own design</h1>

      <div className='cards'>
        <div className='home-card'>
          <img src='https://cdn.pixabay.com/photo/2021/10/28/11/51/kids-6749530_1280.jpg' alt="" />
          <div className='card-text'>
            <h2>Comfort</h2>
            <p>Discover our new collection. Comfort and style meet in every size!</p>
          </div>
        </div>

        <div className='home-card'>
          <img src='https://cdn.pixabay.com/photo/2019/07/27/21/42/t-shirt-4367577_1280.jpg' alt="" />
          <div className='card-text'>
            <h2>Sizes and colors</h2>
            <p>Sizes and colors to meet all customer needs</p>
          </div>
        </div>

        <div className='home-card'>
          <img src='https://cdn.pixabay.com/photo/2017/05/28/18/38/t-shirt-2351761_1280.jpg' alt="" />
          <div className='card-text'>
            <h2>Detailed printing</h2>
            <p>High quality prints printed on quality and comfortable fabrics</p>
          </div>
        </div>
      </div>


      <Box sx={{ width: '80vw', height: 600, overflowY: 'scroll', margin: '30px 0', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
        scrollbarWidth: 'none', /* For Firefox */
        '&::-webkit-scrollbar': {
          display: 'none', /* For Chrome, Safari, and Edge */
        }
      }}>
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.title}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

      <Footer />

    </div>
  );
}

export default Home;

const itemData = [
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-22/cr3ie3le878c73c2jj20/a1420768bbc977a555d5e414f6eb7fabba6c3cd2_high.webp',
    title: '1',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n1kle878c738aeqqg/b4b914c1590867ae4432daace82c905e0c54806e_high.webp',
    title: '2',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n2j5e878c73fas0c0/e41948afb11747c1fbc57400da8c2db8981b98a2_high.webp',
    title: '3',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n2ude878c73fastc0/5b637ef37cb5e2c72caff0c6eda2c849b6894c70_high.webp',
    title: '4',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n2ude878c73fastc0/8583f86defb99ce5f09fb0f280fa9463316426ce_high.webp',
    title: '5',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n3cde878c73fau20g/7231f0113e2316b4371e04763f26c3ce767108e6_high.webp',
    title: '6',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n3q5e878c73evkog0/194e5c76b9dbee2b00e800109012f3b37d8129ca_high.webp',
    title: '7',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n45de878c73evlnlg/2dc70e0b1abff1630f0317c2da66a8489e152326_high.webp',
    title: '8',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n6vte878c738atbpg/de13fd94c1fb74872e484c2532b2d3f14588d871_high.webp',
    title: '9',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n6vte878c738atbpg/5377aa3102fcab2271b68712ae9c92ee4223739b_high.webp',
    title: '10',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n6jle878c73fb5r00/9aa85bda97c0f0322d277f94278f29912c20ca73_high.webp',
    title: '11',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n5ele878c73fb31lg/83452badbda528029c793488b0ea175b566441d3_high.webp',
    title: '12',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n5ele878c73fb31lg/acedae6c5978881e326cc55e80ac282e1cf55240_high.webp',
    title: '13',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n57te878c738aoif0/a0e0c2f2b6df24553313d61218a3c7a138f23c5c_high.webp',
    title: '14',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n4pde878c73fb1cl0/f7e492119f058b76b7f84356e5880491c3513839_high.webp',
    title: '15',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n45de878c73evlnlg/59753693cebd1ea6f690426ba8988d9d7501076f_high.webp',
    title: '16',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n4ale878c73fb08r0/387824d073dc1b6a78b95b321fead07d3d92caf6_high.webp',
    title: '17',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n4ale878c73fb08r0/0aa8022832d015dfb4f5e5f62d26ab8693430128_high.webp',
    title: '18',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n2ude878c73fastc0/8583f86defb99ce5f09fb0f280fa9463316426ce_high.webp',
    title: '19',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n45de878c73evlnlg/2dc70e0b1abff1630f0317c2da66a8489e152326_high.webp',
    title: '20',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n3q5e878c73evkog0/194e5c76b9dbee2b00e800109012f3b37d8129ca_high.webp',
    title: '21',
  },
  {
    img: 'https://image.cdn2.seaart.ai/2024-08-28/cr7n3cde878c73fau20g/7231f0113e2316b4371e04763f26c3ce767108e6_high.webp',
    title: '22',
  },
];