import {
  HttpClientService,
  RequestParameters,
} from "../../services/common/httpClientSevice";

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const httpClient = new HttpClientService(baseUrl);

export default class RoleService {
  async getUserRoles(userId) {
    const requestParameters = new RequestParameters(
      "users",
      `GetRoles/${userId}`
    );
    return await httpClient.get(requestParameters);
  }
  async getRoles() {
    const requestParameters = new RequestParameters(
      "roles",
      "GetDynamic?PageIndex=0&PageSize=10"
    );
    return await httpClient.post(requestParameters, {});
  }
}
