import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "../styles/designs.css";
import Sidebar from "../components/Sidebar";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  DialogContentText,
  ImageList,
  ImageListItemBar,
  ImageListItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PublicIcon from "@mui/icons-material/Public";
import PrivateIcon from "@mui/icons-material/Lock";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import DraftService from "../services/models/draftService";
import StorageManager from "../services/common/storageManager";
import TokenDecoder from "../services/common/tokenDecoder";
import DraftImageService from "../services/models/draftImageService";
import Carousel from "react-material-ui-carousel";
import { toastrService } from "../services/common/toasterService";

const BASE_IMG_URL = process.env.REACT_APP_IMAGES_BASE_URL;

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 300, // Adjusted width to be larger for carousel
  height: 300, // Adjusted height to be larger for carousel
  position: "relative",
  transition: "transform 0.3s, background-color 0.3s, color 0.3s",
  color: "grey",
  "&:hover": {
    transform: "scale(1.05)",
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.warning.main,
    cursor: "pointer",
  },
}));

function Designs() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [title, setTitle] = useState("");
  const [drafts, setDrafts] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentDraftId, setCurrentDraftId] = useState(null);

  const navigate = useNavigate();
  const draftService = new DraftService();
  const draftImageService = new DraftImageService();
  const storageManager = new StorageManager();
  const tokenDecoder = new TokenDecoder();
  const userId = tokenDecoder.getUserId(storageManager.getToken().token);

  const getDrafts = async (userId) => {
    return await draftImageService.getDraftImagesByUserId(userId);
  };

  const convertDrafts = async () => {
    const response = await getDrafts(userId);
    console.log(response);
    const data = response.items;
    const groupedDrafts = data.reduce((acc, item) => {
      const { draftId, title, userId, isPrivate, isFrontImage, path } = item;

      // Grup zaten mevcutsa, image path'lerini ekle
      if (acc[draftId]) {
        acc[draftId].images.push({ isFrontImage, path });
      } else {
        // Yeni bir grup oluştur
        acc[draftId] = {
          draftId,
          title,
          userId,
          isPrivate,
          images: [{ isFrontImage, path }],
        };
      }

      return acc;
    }, {});

    // Her grup için images listesini sıralayarak isFrontImage true olanı başa al
    const draftsWithSortedImages = Object.values(groupedDrafts).map((draft) => {
      draft.images.sort((a, b) => b.isFrontImage - a.isFrontImage);
      return draft;
    });

    setDrafts(draftsWithSortedImages || []);
  };

  const handleResize = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    convertDrafts();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [userId]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleNewDraft = async () => {
    if (title.trim()) {
      const draft = {
        title: title,
        userId: userId,
      };
      const response = await draftService.addDraft(draft);
      setCurrentDraftId(response.id);
      navigate(`/design/${response.id}`);
      handleClose();
    }
  };

  const handleCardClick = (draftId) => {
    navigate(`/design/${draftId}`);
  };

  const handleDeleteClick = (draftId) => {
    console.log("delete: ", draftId);
    setCurrentDraftId(draftId);
    setDeleteDialogOpen(true);
    
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    await draftService.deleteDraft(currentDraftId);
    setDeleteDialogOpen(false);
    getDrafts(userId);
    window.location.reload()
  };

  return (
    <div className="design-container">
      <Navbar />
     
        <div className="profile">
          {(windowDimensions.width > 738) && <Sidebar />}
          <div className="designs">
            <StyledCard onClick={handleClickOpen} style={{backgroundColor:'transparent', boxShadow: '0 2px 8px rgba(255, 255, 255, 0.5)', color:'white'}}>
              <CardContent >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <AddIcon style={{ fontSize: 60 }} />
                  <Typography
                    variant="h6"
                    style={{ marginTop: 8, fontFamily: "Roboto, sans-serif" }}
                  >
                    Start New Design
                  </Typography>
                </Box>
              </CardContent>
            </StyledCard>
            <Dialog open={openDialog} onClose={handleClose}>
              <DialogTitle>Create New Design</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="title"
                  label="Title"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={title}
                  onChange={handleChangeTitle}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleNewDraft}>Create</Button>
              </DialogActions>
            </Dialog>

            {/* Design Card with Carousel */}
            {drafts.map((draft, index) => (
              <StyledCard key={index} style={{backgroundColor:'transparent', boxShadow: '0 2px 8px rgba(255, 255, 255, 0.5)', color:'white'}}>
                <CardContent>
                  <Box className="designs-card">
                    {" "}
                    <Carousel className="card-image-carosuel" autoPlay= {false}>
                      {draft.images.map((image, index) => (
                        <img
                          key={index}
                          src={BASE_IMG_URL + image.path || "../../public/logo.png"}
                          alt={""}
                          style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "contain",
                            borderRadius: "4px",
                            marginTop: "25px",
                          }}
                        />
                      ))}
                    </Carousel>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      style={{ marginTop: 8, cursor: "pointer" }} // Birleştirilmiş stil
                      onClick={() => handleCardClick(draft.draftId)}
                    >
                      <Typography
                        variant="h6"
                        style={{ fontFamily: "Roboto, sans-serif" }}
                      >
                        {draft.title}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ marginLeft: "16px" }}
                      >
                        {" "}
                        {draft.isPrivate ? <PrivateIcon /> : <PublicIcon />}
                      </Box>
                    </Box>
                    <IconButton
                      style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: "rgb(230, 109, 65)",
                        color: "white",
                        zIndex: "9",
                      }}
                      onClick={() => handleDeleteClick(draft.draftId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </StyledCard>
            ))}
          </div>
        </div>
      
      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
        <DialogTitle>
          {"Are you sure you want to delete this design?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action cannot be undone. Please confirm if you want to delete
            this design.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Designs;
