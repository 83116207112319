import React from "react";
import { Box, Typography, Tooltip, Button, Stack } from "@mui/material";
import TokenIcon from "@mui/icons-material/Token";

const CoinButton = () => {
  return (
    <Tooltip
      title={
        <Box sx={{ width: 200, height: 100, p: 1 }}>
          <Stack spacing={1}>
            <Typography variant="body2">
              Choose the package that suits your needs
            </Typography>
            <Button variant="contained" sx={{backgroundColor:"#ff7979"}} size="small">
              Go to packages
            </Button>
          </Stack>
        </Box>
      }
      placement="top"
      arrow
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "black",
          color: "#ff7979",
          boxShadow: "inset 0px 0px 10px rgba(0, 0, 0, 0.5)",
          borderRadius: "8px",
          padding: "10px",
          width: "%100",
          cursor: "pointer",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <Typography variant="h6">
          1000 Coin <TokenIcon />
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default CoinButton;
