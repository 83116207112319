import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "../styles/product.css";
import Sidebar from "../components/Sidebar";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  DialogContentText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PublicIcon from "@mui/icons-material/Public";
import PrivateIcon from "@mui/icons-material/Lock";
import { styled } from "@mui/material/styles";
import Carousel from "react-material-ui-carousel";
import ProductService from "../services/models/productService";
import AddToCartButton from "../components/AddToCartButton";
import TokenDecoder from "../services/common/tokenDecoder";
import StorageManager from "../services/common/storageManager";

const BASE_IMG_URL = process.env.REACT_APP_IMAGES_BASE_URL;

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 300,
  height: 300,
  position: "relative",
  transition: "transform 0.3s, background-color 0.3s, color 0.3s",
  color: "grey",
  "&:hover": {
    transform: "scale(1.05)",
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.warning.main,
    cursor: "pointer",
  },
}));

function Product() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [products, setProducts] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);

  const storageManager = new StorageManager();
  const tokenDecoder = new TokenDecoder();
  const userId = tokenDecoder.getUserId(storageManager.getToken().token);

  const productService = new ProductService();

  const getProducts = async () =>
    await productService
      .getProductByUserId(userId)
      .then((res) => setProducts(res.items));

  const handleResize = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    getProducts();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const handleDeleteClick = (productId) => {
    setCurrentProductId(productId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    await productService.deleteProduct(currentProductId);
    setDeleteDialogOpen(false);
    getProducts();
  };

  return (
    <div className="design-container">
      <Navbar />

        <div className="profile">
          {windowDimensions.width > 992 && <Sidebar />}
          <div className="designs">
            {products.map((product, index) => (
              <StyledCard key={index} style={{height:'90%', backgroundColor:'transparent', boxShadow: '0 2px 8px rgba(255, 255, 255, 0.5)', color:'white'}}>
                <CardContent>
                  <Box className="designs-card">
                    {" "}
                    <Carousel className="card-image-carosuel" autoPlay= {false}>
                      <img
                        key={index}
                        src={
                          BASE_IMG_URL + product.frontImagePath ||
                          "../../public/logo.png"
                        }
                        alt={""}
                        style={{
                          width: "250px",
                          height: "200px",
                          objectFit: "contain",
                          borderRadius: "4px",
                          marginTop: "25px",
                          marginLeft:'10px'
                        }}
                      />
                      <img
                        key={index}
                        src={
                          BASE_IMG_URL + product.backImagePath ||
                          "../../public/logo.png"
                        }
                        alt={""}
                        style={{
                          width: "250px",
                          height: "200px",
                          objectFit: "contain",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          marginTop: "25px",
                        }}
                      />
                    </Carousel>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      style={{ marginTop: 8, cursor: "pointer" }}
                    >
                      <Typography
                        variant="h6"
                        style={{ fontFamily: "Roboto, sans-serif" }}
                      >
                        {product.name}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ marginLeft: "16px" }}
                      >
                        {" "}
                        {product.isPrivate ? <PrivateIcon /> : <PublicIcon />}
                      </Box>
                    </Box>
                    <IconButton
                      style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: "rgb(230, 109, 65)",
                        color: "white",
                        zIndex: "9",
                      }}
                      onClick={() => handleDeleteClick(product.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <AddToCartButton productId={product.id} userId={userId} />
                  </Box>
                </CardContent>
              </StyledCard>
            ))}
          </div>
        </div>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
        <DialogTitle>
          {"Are you sure you want to delete this product?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action cannot be undone. Please confirm if you want to delete
            this product.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Product;
