import React from "react";
import StorageManager from "../services/common/storageManager";
import "../styles/login.css";

export const Logout = () => {
  const onLogoutSuccess = () => {
    console.log("Log out succesfull!");

    const storageManager = new StorageManager();
    storageManager.removeUserInfos();
    storageManager.removeToken();

    window.location.reload();
  };
  return (
    <div className="logout">
      <button className="logout-button" onClick={onLogoutSuccess}>
        Logout
      </button>
    </div>
  );
};
