import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "../styles/AdminLayout.css";
import { Link } from "react-router-dom";
import { Logout } from "../../components/Logout";

const AdminLayout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="admin-layout">
      <div className="admin-content">
        <div className={`admin-sidebar ${isCollapsed ? "collapsed" : ""}`}>
          <button className="sidebar-toggle" onClick={toggleSidebar}>
            {isCollapsed ? ">" : "<"}
          </button>
          <nav className={`sidebar-nav ${isCollapsed ? "collapsed" : ""}`}>
            <ul>
              <li>
                <Link to="/admin">Dashboard</Link>
              </li>
              <li>
                <Link to="/admin/users">Users</Link>
              </li>
              <li>
                <Link to="/admin/drafts">Drafts</Link>
              </li>
              <li>
                <Link to="/admin/products">Products</Link>
              </li>
              <li>
                <Link to="/admin/orders">Orders</Link>
              </li>
              <li>
                <Link to="/admin/settings">Settings</Link>
              </li>
              <li>
                <Logout/>
              </li>
            </ul>
          </nav>
        </div>
        <main className={`admin-main ${isCollapsed ? "collapsed" : ""}`}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
