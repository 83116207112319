import {createSlice} from "@reduxjs/toolkit"
const initialState = localStorage.getItem("totalCartQuantity") || 0

const cartSlice = createSlice({
    name: 'cart',
    initialState,

    reducers: {
        updateCart(state,action) {
            const {quantity} = action.payload
            return quantity
        }
    }
})

export const {updateCart} = cartSlice.actions
export default cartSlice.reducer