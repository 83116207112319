import { HttpClientService, RequestParameters } from "../../services/common/httpClientSevice"; 

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const httpClient = new HttpClientService(baseUrl);


export default class ProductService{  
  async getProducts(pageIndex,pageSize){
    const requestParameters= new RequestParameters("products",null,`PageIndex=${pageIndex}&PageSize=${pageSize}`);
    return await httpClient.get(requestParameters);
  }

  async getProductByUserId(userid){
    const requestParameters= new RequestParameters("products",`GetByUserId/${userid}`);
    return await httpClient.get(requestParameters);
  }

  async getProductById(id){
    const requestParameters= new RequestParameters("products",`GetById/${id}`);
    return await httpClient.get(requestParameters);
  }

  async createProduct(product){
    const requestParameters= new RequestParameters(`products`);
    return await httpClient.post(requestParameters,product);
  }

  async addProductImages(productImages){
    const requestParameters= new RequestParameters(`productImages`);
    return await httpClient.post(requestParameters,productImages);
  }

  async deleteProduct(id){
    const requestParameters= new RequestParameters("products");
    return await httpClient.delete(requestParameters,id);
  }
  
}