import React from "react";
import { LineChart } from '@mui/x-charts/LineChart';
import '../styles/dashboard.css'
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';

const Dashboard = () => {
  return (
    <div className="dashboard">

      <h3>Dashboard</h3>

      <div className="statistics">
        <div className="chart">
          <h5>Total Order Number</h5>
          <LineChart
            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
            series={[
              {
                data: [2, 5.5, 2, 8.5, 1.5, 5],
              },
            ]}
            width={450}
            height={300}
          />
        </div>

        <div className="chart">
          <h5>Total Visits</h5>
          <LineChart
            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
            series={[
              {
                data: [2, 5.5, 2, 8.5, 1.5, 5],
              },
            ]}
            width={450}
            height={300}
          />
        </div>

        <div className="chart">
          <h5>Total Sales</h5>
          <BarChart
            xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
            series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
            width={450}
            height={300}
          />
        </div>

        <div className="chart">
          <h5>Age</h5>
          <PieChart
            series={[
              {
                data: [
                  { id: 0, value: 10, label: 'series A' },
                  { id: 1, value: 15, label: 'series B' },
                  { id: 2, value: 20, label: 'series C' },
                ],
              },
            ]}
            width={450}
            height={300}
          />
        </div>

        <div className="chart">
          <h5>Gender</h5>
          <PieChart
            series={[
              {
                data: [
                  { id: 0, value: 10, label: 'series A' },
                  { id: 1, value: 15, label: 'series B' },
                  { id: 2, value: 20, label: 'series C' },
                ],
              },
            ]}
            width={450}
            height={300}
          />
        </div>

      </div>

    </div>
  );
};

export default Dashboard;