import React from "react";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import "../styles/sidebar.css";
import StorageManager from "../services/common/storageManager";

export default function Sidebar() {


  const storageManager = new StorageManager();
  const userInfos = storageManager.getUserInfos();

 

  return (
    <div className="side-navbar">
      <div className="user-name">{userInfos.name.toUpperCase()}</div>
      <div className="side-navbar-card">
        <h5>Orders</h5>
        <a className="sidebar-link" href="/profile/orders"><AssignmentOutlinedIcon /> All Orders</a>
        <a className="sidebar-link" href="/profile/orders"><ThumbUpAltOutlinedIcon /> My Reviews</a>
        <a className="sidebar-link" href="/profile/orders"><ShoppingCartOutlinedIcon /> Buy Again</a>
      </div>
      <div className="side-navbar-card">
        <h5>Account & Help</h5>
        <a className="sidebar-link" href="/profile/"><PersonOutlineOutlinedIcon /> Profile</a>
        <a className="sidebar-link" href="/profile/addresses"><LocationOnOutlinedIcon /> Addresses</a>
        <a className="sidebar-link" href="/profile/designs"><DesignServicesOutlinedIcon /> Designs</a>
        <a className="sidebar-link" href="/profile/products"><LocalMallOutlinedIcon /> Products</a>
        <a className="sidebar-link" href="/profile/addresses"><HelpOutlineOutlinedIcon /> Help</a>
      </div>
    </div>
  );
  
}
