import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Bounce } from "react-toastify";

export const toastrService = {
message: (message, { messageType, position }) => {
const commonOptions = {
position: position || "bottom-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "light",
transition: Bounce,
};

switch (messageType) {
case "warn":
toast.warn(message, commonOptions);
break;
case "success":
toast.success(message, commonOptions);
break;
case "error":
toast.error(message, commonOptions);
break;
default:
toast.info(message, commonOptions);
break;
}
},
};

export const ToastContainerWrapper = () => <ToastContainer />;