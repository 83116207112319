import axiosInstance from "./httpInterceptor";

// Create a class for HTTP client service
class HttpClientService {
  constructor(baseUrl) {
  this.baseUrl = baseUrl;
  this.axiosInstance = axiosInstance;
  }

  // Private method to construct the URL
  url(requestParameter) {
    return `${requestParameter.baseUrl || this.baseUrl}/${
      requestParameter.controller
    }${requestParameter.action ? `/${requestParameter.action}` : ""}`;
  }

  // Method to perform a GET request
  async get(requestParameter, id = "") {
    let url = "";
    if (requestParameter.fullEndPoint) {
      url = requestParameter.fullEndPoint;
    } else {
      url = `${this.url(requestParameter)}${id ? `/${id}` : ""}${
        requestParameter.queryString ? `?${requestParameter.queryString}` : ""
      }`;
    }
    try {
      //console.log(url);
      const response = await this.axiosInstance.get(url, {
        headers: requestParameter.headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error during GET request:", error);
      throw error;
    }
  }

  // Method to perform a POST request
  async post(requestParameter, body) {
    let url = "";
    if (requestParameter.fullEndPoint) {
      url = requestParameter.fullEndPoint;
    } else {
      url = `${this.url(requestParameter)}${
        requestParameter.queryString ? `?${requestParameter.queryString}` : ""
      }`;
    }
    try {
      const response = await this.axiosInstance.post(url, body, {
        headers: requestParameter.headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error during POST request:", error);
      throw error;
    }
  }

  // Method to perform a PUT request
  async put(requestParameter, body) {
    let url = "";
    if (requestParameter.fullEndPoint) {
      url = requestParameter.fullEndPoint;
    } else {
      url = `${this.url(requestParameter)}${
        requestParameter.queryString ? `?${requestParameter.queryString}` : ""
      }`;
    }
    try {
      const response = await this.axiosInstance.put(url, body, {
        headers: requestParameter.headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error during PUT request:", error);
      throw error;
    }
  }

  // Method to perform a DELETE request
  async delete(requestParameter, id) {
    let url = "";
    if (requestParameter.fullEndPoint) {
      url = requestParameter.fullEndPoint;
    } else {
      url = `${this.url(requestParameter)}/${id}${
        requestParameter.queryString ? `?${requestParameter.queryString}` : ""
      }`;
    }
    try {
      const response = await this.axiosInstance.delete(url, {
        headers: requestParameter.headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error during DELETE request:", error);
      throw error;
    }
  }

  // Method to perform a PATCH request
  async patch(requestParameter, body) {
    let url = "";
    if (requestParameter.fullEndPoint) {
      url = requestParameter.fullEndPoint;
    } else {
      url = `${this.url(requestParameter)}${
        requestParameter.queryString ? `?${requestParameter.queryString}` : ""
      }`;
    }
    try {
      const response = await this.axiosInstance.patch(url, body, {
        headers: requestParameter.headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error during PATCH request:", error);
      throw error;
    }
  }
}

// RequestParameters class
class RequestParameters {
  constructor(controller, action, queryString, headers, baseUrl, fullEndPoint) {
    this.controller = controller;
    this.action = action;
    this.queryString = queryString;
    this.headers = headers;
    this.baseUrl = baseUrl;
    this.fullEndPoint = fullEndPoint;
  }
}

export { HttpClientService, RequestParameters };
