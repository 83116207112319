import React from "react";
import { Button } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch } from "react-redux";
import { updateCart } from "../redux/reducers/cartReducer";
import { toastrService } from "../services/common/toasterService";
import BasketService from "../services/models/basketService";

const AddToCartButton = ({ productId, userId }) => {
  const dispatch = useDispatch();
  const basketService = new BasketService();

  const handleAddToCart = async () => {
    //Todo Bu api call, giriş yapıldığında çağırılıp redux ile tutulsa nasıl olur
    //basket'ın id'si
    //total count ve price redux'ta tutulsun
    const activeBaskets = await basketService.getBasketByUserId(userId);

    const basketItem = {
      productId: productId,
      basketId: activeBaskets.id,
      quantity: 1,
    };

    await basketService.addBasketItem(basketItem).then(() => {
      toastrService.message("Product was added to your cart", {
        messageType: "success",
        position: "bottom-right",
      });
    });

    const totalQuantity = activeBaskets.basketItems.reduce(
      (sum, item) => sum + item.quantity,
      0
    );
    localStorage.setItem("totalCartQuantity", totalQuantity + 1);

    dispatch(updateCart({ quantity: totalQuantity + 1 }));
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<ShoppingCartIcon />}
      style={{
        marginTop: "16px",
        width: "100%",
        fontFamily: "Roboto, sans-serif",
      }}
      onClick={handleAddToCart}
    >
      Add to Cart
    </Button>
  );
};

export default AddToCartButton;
