import { HttpClientService, RequestParameters } from "../../services/common/httpClientSevice"; 

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const httpClient = new HttpClientService(baseUrl);


export default class BasketService{  
  async getBasketByUserId(userId){      
    const requestParameters= new RequestParameters("baskets","GetActive");
    return await httpClient.get(requestParameters,userId);
  }  

  async getBasketContent(userId){      
    const requestParameters= new RequestParameters("baskets","GetWithAddress");
    return await httpClient.get(requestParameters,userId);
  }  

  async updateBasket(basket){
    const requestParameters= new RequestParameters(`baskets`);
    return await httpClient.put(requestParameters,basket);
  }

 //BasketItems
  async getBasketItemsByBasketId(basketId){
    const requestParameters= new RequestParameters("basketItems","GetByBasketId");
    return await httpClient.get(requestParameters,basketId);
  }

  async addBasketItem(basketItem){
    const requestParameters= new RequestParameters("basketItems");
    return await httpClient.post(requestParameters,basketItem);
  }

  async deleteBasketItem(id){
    const requestParameters= new RequestParameters("basketItems");
    return await httpClient.delete(requestParameters,id);
  }

  async updateBasketItem(basketItem){
    const requestParameters= new RequestParameters("basketItems");
    return await httpClient.put(requestParameters,basketItem);
  }

  
  
}