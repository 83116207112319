import React, { useState, useEffect, useRef } from "react";
import "../styles/navbar.css";
import BrushIcon from "@mui/icons-material/Brush";
import Login from "./Login";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import StorageManager from "../services/common/storageManager";
import { Logout } from "./Logout";
import { checkIsAuth } from "../redux/actions/authActions";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CoinButton from "./CoinButton";

function Navbar() {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkIsAuth());
  }, [dispatch]);

  useEffect(() => {
    console.log("isAuth changed: ", isAuth);
  }, [isAuth]);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [myArray, setMyArray] = useState([]);

  const storageManager = new StorageManager();
  const userInfos = storageManager.getUserInfos();

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("active-menu");
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const storedArray = JSON.parse(localStorage.getItem("productArray")) || [];
    setMyArray(storedArray);
    console.log(userInfos);
  }, []);

  const total_item = myArray.reduce(
    (sum, item) => sum + parseInt(item.quantity),
    0
  );

  const cart = useSelector(store => store.cart)

  return (
    <>
      <nav className="navbar">
        <div className="logo">
          <a href="/">
            <img className="logo" src="/logo.png" alt="Logo" />
          </a>
          <a href="/" >Redingale</a>
        </div>
        <ul>
        <li>
            <a href="/pricing">
              <CoinButton/>
            </a>
            <a href="/pricing">
              Pricing <AttachMoneyIcon />
            </a>
          </li>
          <li>
            <a href="/products">
              Products <SearchIcon />
            </a>
          </li>
          {/* {isAuth && (
            <li>
              <a href="/profile/designs">
                Design <BrushIcon />
              </a>
            </li>
          )} */}
          {isAuth && (
            <li>
              <a href="/cart">
                Cart <ShoppingCartIcon />
              </a>
              <span id="cart-num">{cart}</span>
            </li>
          )}
          {isAuth && (
            <li id="mu-menu">
              <Stack direction="row" spacing={2}>
                <div>
                  <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    style={{ color: "black" }}
                  >
                    <img
                      className="navbar-img"
                      src={
                        userInfos.picture ?? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      alt="Profile"
                    />
                    <ArrowDropDownIcon />
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-end"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-end"
                              ? "right top"
                              : "right bottom",
                        }}
                      >
                        <Paper style={{zIndex: '999', backgroundColor:'rgba(0, 0, 0, 0.412)'}}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                                width: "150px",
                                zIndex:'999',
                              }}
                            >
                              <MenuItem onClick={handleClose}>
                                <a href="/profile">Profile</a>
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                <a href="/profile/orders">My Orders</a>
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                <a href="/profile/designs">My Designs</a>
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                {isAuth && (
                                  <a href="#">
                                    <Logout />
                                  </a>
                                )}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Stack>
            </li>
          )}
          {!isAuth && (
            <li className="sign-in">
              <a href="#">
                <Login />
              </a>
            </li>
          )}
        </ul>
        <div className="toggle-button" onClick={showNavbar}>
          <MenuIcon style={{ color: "black" }} />
        </div>
      </nav>

      <div className="dropdown-menu" ref={navRef}>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          {isAuth && <li>
            <Accordion className='accordion' style={{border:'none', boxShadow:'none'}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Profile
              </AccordionSummary>
              <AccordionDetails style={{display:'flex', flexDirection:'column', textAlign:'center', gap:'5px', marginTop:'-15px'}}>
                <a href="/profile">Profile</a>
                <a href="/profile/addresses">Addresses</a>
                <a href="/profile/designs">Desings</a>
                <a href="/profile/orders">All Orders</a>
                <a href="/profile/orders">My Reviews</a>
                <a href="/profile/orders">Buy Again</a>
              </AccordionDetails>

            </Accordion>
          </li>}
          {isAuth && (
            <li>
              <Logout />
            </li>
          )}
          {!isAuth && <li>
            <Login />
          </li>}

        </ul>
      </div>
    </>
  );
}

export default Navbar;
