// src/MembershipPage.js
import React from 'react';
import '../styles/pricing.css';
import MembershipCard from '../components/MembershipCard';
import Navbar from '../components/Navbar';

const Pricing = () => {
    return (
        <div className='pricing'>
            <Navbar/>

            <div className="membership-container">
                <h1>Our Membership Plans</h1>
                <div className="card-container">
                    <MembershipCard
                        title="Bronze"
                        price="$9.99/month"
                        features={["Basic Support", "100 Token", "Monthly Updates"]}
                        background="rgba(91, 91, 91, 0.455)"
                    />
                    <MembershipCard
                        title="Gold"
                        price="$19.99/month"
                        features={["Priority Support", "250 Token", "Weekly Updates"]}
                        background="rgba(91, 91, 91, 0.455)"
                    />
                    <MembershipCard
                        title="Platinum"
                        price="$29.99/month"
                        features={["24/7 Support", "500 Token", "Daily Updates"]}
                        background="rgba(91, 91, 91, 0.455)"
                    />
                </div>
            </div>

        </div>
    );
};

export default Pricing;
