// src/MembershipCard.js
import React from 'react';
import '../styles/membershipcard.css';

const MembershipCard = ({ title, price, features, background }) => {
  return (
    <div className="card" style={{ backgroundColor: background }}>
      <h2 className="card-title">{title}</h2>
      <p className="card-price">{price}</p>
      <ul className="card-features">
        {features.map((feature, index) => (
          <li key={index} className="feature">{feature}</li>
        ))}
      </ul>
      <button className="card-button">Join Now</button>
    </div>
  );
};

export default MembershipCard;
