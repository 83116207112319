import {
  HttpClientService,
  RequestParameters,
} from "../../services/common/httpClientSevice";
import TokenDecoder from "../common/tokenDecoder";

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const httpClient = new HttpClientService(baseUrl);

export default class AuthService {
  async googleLogin(credentialResponse) {
    const tokenDecoder = new TokenDecoder();

    const googleLoginRequest = tokenDecoder.getGoogleLoginRequest(credentialResponse);
    const userInfos = tokenDecoder.getUserInfos(credentialResponse);

    const requestParameters = new RequestParameters("Auth", "LoginWithGoogle");
    const response = await httpClient.post(
      requestParameters,
      googleLoginRequest
    );

    return { response: response, userInfos: userInfos };
  }

  async assignRole(userId, roleId) {
    const requestParameters = new RequestParameters("Auth", "AssignRoleToUser");
    const response = await httpClient.post(requestParameters, {
      userId: userId,
      roleId: roleId,
    });
    return response;
  }

  async removeUserRole(userId, roleId) {
    const requestParameters = new RequestParameters("Auth", "RemoveRoleToUser");
    const response = await httpClient.post(requestParameters, {
      userId: userId,
      roleId: roleId,
    });
    return response;
  }

  async banUser(userId) {
    const requestParameters = new RequestParameters(
      "Auth",
      `BanUser/${userId}`
    );
    const response = await httpClient.patch(requestParameters);
    return response;
  }

  async removeUserBan(userId) {
    const requestParameters = new RequestParameters(
      "Auth",
      `RemoveBanUser/${userId}`
    );
    const response = await httpClient.patch(requestParameters);
    return response;
  }
}
