import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import DraftService from "../../services/models/draftService";
import { Typography } from "@mui/material";
import BackgroundBox from "../components/BackgroundBox";
import "../styles/drafts.css"

const columns = [
  { field: "id", headerName: "ID", width: 150 },
  { field: "title", headerName: "Title", width: 180 },
  { field: "email", headerName: "Email", width: 180 },
  //{ field: "typeId", headerName: "Type ID", width: 180 },
  { field: "size", headerName: "Size", width: 150 },
  { field: "isPrivate", headerName: "Is Private", width: 150, type: "boolean" },
  { field: "isCompleted", headerName: "Is Completed", width: 150, type: "boolean" },
  { field: "frontImagePath", headerName: "Front Image Path", width: 150 },
  { field: "backImagePath", headerName: "Back Image Path", width: 150 },
  { field: "createdDate", headerName: "Created Date", width: 150 },
  { field: "updatedDate", headerName: "Updated Date", width: 150 },
];

const Drafts = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState(null);

  const draftService = new DraftService();

  const fetchData = async (pageIndex, pageSize) => {
    try {
      const response = await draftService.getDraftsDynamic(pageIndex, pageSize);
      setRows(response.items);
      setTotalCount(response.count); // Total count for pagination
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err);
    }
  };

  useEffect(() => {
    fetchData(paginationModel.page, paginationModel.pageSize);
  }, [paginationModel.page, paginationModel.pageSize]);

  if (error) return <div>Error: {error.message}</div>;
  if (!rows.length) return <div>Loading...</div>;

  return (
    <div className="drafts-container">
      <Typography variant="h3" gutterBottom sx={{ color: "black.300" }}>
        Drafts
      </Typography>
      
        <div className="data-grid">
          <DataGrid
            rows={rows}
            columns={columns}
            paginationModel={paginationModel}
            pageSizeOptions={[5, 10, 25]}
            onPaginationModelChange={setPaginationModel}
            paginationMode="server"
            rowCount={totalCount}
          />
        </div>
      
    </div>
  );
};

export default Drafts;
