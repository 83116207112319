import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import UserService from "../../services/models/userService";
import { useNavigate } from "react-router-dom";
import BackgroundBox from "../components/BackgroundBox";
import "../styles/users.css"

const columns = [
  { field: "id", headerName: "ID", width: 150 },
  { field: "firstName", headerName: "First Name", width: 180 },
  { field: "lastName", headerName: "Last Name", width: 180 },
  { field: "email", headerName: "Email", width: 250 },
  { field: "phoneNumber", headerName: "Phone Number", width: 180 },
  { field: "userName", headerName: "User Name", width: 180 },
  {
    field: "emailConfirmed",
    headerName: "Email Confirmed",
    width: 180,
    type: "boolean",
  },
  {
    field: "phoneNumberConfirmed",
    headerName: "Phone Number Confirmed",
    width: 220,
    type: "boolean",
  },
  {
    field: "twoFactorEnabled",
    headerName: "Two Factor Enabled",
    width: 220,
    type: "boolean",
  },
  { field: "isBanned", headerName: "Is Banned", width: 150, type: "boolean" },
];

const Users = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const userService = new UserService();

  const fetchData = async (pageIndex, pageSize) => {
    try {
      const response = await userService.getUsers(pageIndex, pageSize);
      setRows(response.items);
      setTotalCount(response.count); // Total count for pagination
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err);
    }
  };

  useEffect(() => {
    fetchData(paginationModel.page, paginationModel.pageSize);
  }, [paginationModel.page, paginationModel.pageSize]);

  if (error) return <div>Error: {error.message}</div>;
  if (!rows.length) return <div>Loading...</div>;

  const goToUserDetail = (params) => {
    navigate(`/admin/user/${params.row.id}`); // Navigate to the detail page
  };

  return (
    <div className="users-container">
      <Typography
        variant="h3"
        gutterBottom
        sx={{ color: "black.300"}}
      >
        Users
      </Typography>
      <div className="data-grid">
        <DataGrid
           rows={rows}
           columns={columns}
           paginationModel={paginationModel}
           pageSizeOptions={[5, 10, 25]}
           onPaginationModelChange={setPaginationModel}
           paginationMode="server"
           rowCount={totalCount}
           onRowDoubleClick={goToUserDetail}
        />
      </div>
    </div>
  );
};

export default Users;
