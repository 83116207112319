import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  Grid,
} from "@mui/material";
import ProductService from "../../services/models/productService";

const baseImageUrl = process.env.REACT_APP_IMAGES_BASE_URL;

const ProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const productService = new ProductService();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const data = await productService.getProductById(productId);
        setProduct(data);
      } catch (err) {
        setError("Failed to load product data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>{error}</Typography>;
  if (!product) return <Typography>No product data available</Typography>;

  const {
    name,
    price,
    draftId,
    likeCount,
    isPrivate,
    frontImagePath,
    backImagePath,
  } = product;

  console.log(product);

  return (
    <Card
      sx={{
        maxWidth: 800,
        margin: "auto",
        border: "5px solid",
        borderColor: "grey.300",
        borderRadius: "8px",
      }}
    >
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {name}
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Price: ${price}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Draft ID: {draftId}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Likes: {likeCount}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {isPrivate ? "Private" : "Public"}
        </Typography>
      </CardContent>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <CardMedia
              component="img"
              image={baseImageUrl +backImagePath}
              alt="Product Image 1"
              sx={{
                width: "100%", // Ensure the image fills the container width
                height: 300, // Set a fixed height for the images
                borderRadius: "8px",
                boxShadow: 3,
                transition: "transform 0.3s ease-in-out",
                objectFit: "cover", // Maintain aspect ratio while filling the dimensions
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CardMedia
              component="img"
              image={baseImageUrl + frontImagePath}
              alt="Product Image 2"
              sx={{
                width: "100%", // Ensure the image fills the container width
                height: 300, // Set a fixed height for the images
                borderRadius: "8px",
                boxShadow: 3,
                transition: "transform 0.3s ease-in-out",
                objectFit: "cover", // Maintain aspect ratio while filling the dimensions
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default ProductDetail;
