/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Container, Box } from "@mui/material";

const BackgroundBox = ({ children, height }) => (
  <Container
    sx={{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: 2,
    }}
  >
    <Box
      sx={{
        backgroundColor: "white",
        border: "5px solid",
        borderColor: "grey.300",
        borderRadius: "8px",
        boxShadow: 3,
        padding: 2,
        flex: height ? "none" : 1,
        height: height || "auto",
        width: "100%",
        overflow: height ? "auto" : "hidden",
      }}
    >
      {children}
    </Box>
  </Container>
);

export default BackgroundBox;

// <CustomBox>
//   {/* İçerik buraya gelecek */}
// </CustomBox>

// <CustomBox height={600}>
//   {/* İçerik buraya gelecek */}
// </CustomBox>
