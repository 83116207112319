/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { checkIsAuth } from "../../redux/actions/authActions";
import TokenDecoder from "../../services/common/tokenDecoder";
import StorageManager from "../../services/common/storageManager";

const PrivateRoute = ({ element, roles }) => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(checkIsAuth());
  }, [isAuth,location]);


  const tokenDecoder = new TokenDecoder();
  const storageManager = new StorageManager();
  const userRoles =     tokenDecoder.getRoles(storageManager.getToken()?.token) || [];
  roles=roles??[];
  const hasRequiredRole = roles.length === 0 || roles.some((role) => userRoles.includes(role));

  // console.log("roles: ", roles);

  if (isLoading) return <div>Loading...</div>;

  if (!isLoading) {
    if (!isAuth) 
      return <Navigate to="/login" state={{ from: location }} replace />;
    if (!hasRequiredRole)
      return <Navigate to="/"/>;

    return element;
  }
};

export default PrivateRoute;
