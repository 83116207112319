import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import AuthService from "../services/models/authService";
import StorageManager from "../services/common/storageManager";
import { useDispatch } from "react-redux";
import { checkIsAuth } from "../redux/actions/authActions";

function Login() {
  const dispatch = useDispatch();

  const responseGoogle = async (credentialResponse) => {
    const authService = new AuthService();
    const response = await authService.googleLogin(credentialResponse);
    console.log("Login.response: ", response);

    const storageManager = new StorageManager();
    storageManager.setUserInfos(response.userInfos);
    storageManager.setToken(response.response);
    dispatch(checkIsAuth());
  };

  return (
    <div className="custome-google-button">
      <GoogleLogin
        buttonText="Login"
        onSuccess={responseGoogle}
        onError={() => {
          console.log("Login failed");
        }}
      />
    </div>
  );
}

export default Login;
