import React, { useEffect, useState } from "react";
import "../styles/cart.css";
import Navbar from "../components/Navbar";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import BasketService from "../services/models/basketService";
import TokenDecoder from "../services/common/tokenDecoder";
import StorageManager from "../services/common/storageManager";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import OrderService from "../services/models/orderService";
import AddressService from "../services/models/addressService";
import { toastrService } from "../services/common/toasterService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../redux/reducers/cartReducer";

function Cart() {
  const BASE_IMG_URL = process.env.REACT_APP_IMAGES_BASE_URL;

  const [basket, setBasket] = useState({ basketItems: [], addresses: [] });
  const [selectedAddress, setSelectedAddress] = useState("");
  const navigate = useNavigate();

  const basketService = new BasketService();
  const orderService = new OrderService();
  const storageManager = new StorageManager();
  const tokenDecoder = new TokenDecoder();
  const userId = tokenDecoder.getUserId(storageManager.getToken().token);

  const getBasket = async () =>
    await basketService.getBasketContent(userId).then((res) => setBasket(res));

  useEffect(() => {
    getBasket();
  }, []);

  useEffect(() => {
    if (basket.addresses.length > 0) {
      setSelectedAddress(basket.addresses[0].addressId);
    }
  }, [basket.addresses]);

  useSelector((store) => store.cart);

  const dispatch = useDispatch();

  const removeItem = async (id) => {
    setBasket((prevBasket) => {
      const updatedBasket = {
        ...prevBasket,
        basketItems: Array.isArray(prevBasket.basketItems)
          ? prevBasket.basketItems.filter((item) => item.basketItemId !== id)
          : [],
      };

      basketService.deleteBasketItem(id);
      return updatedBasket;
    });

    const totalQuantity = calculateTotalCount()
    localStorage.setItem("totalCartQuantity", totalQuantity)
    dispatch(updateCart({ quantity: totalQuantity }));
  };

  const updateQuantity = (id, newQuantity) => {
    setBasket((prevBasket) => ({
      ...prevBasket,
      basketItems: prevBasket.basketItems.map((item) =>
        item.basketItemId === id ? { ...item, quantity: newQuantity } : item
      ),
    }));
    basketService.updateBasketItem({ id: id, quantity: newQuantity });
  };

  const calculateTotalCount = () => {
    return basket.basketItems.reduce((total, item) => total + item.quantity, 0);
  };

  const calculateTotal = () => {
    const totalPrice = basket.basketItems
      .reduce((total, item) => total + item.quantity * item.price, 0)
      .toFixed(2);

    return parseFloat(totalPrice);
  };

  const handleCompletePurchase = async () => {
    const totalCount = calculateTotalCount();
    const totalPrice = calculateTotal();

    const newBasket = {
      ...basket,
      totalPrice: totalPrice,
      totalProduct: totalCount,
    };

    setBasket(newBasket);
    
    await orderService
      .createOrder({
        basketId: newBasket.id,
        totalPrice: newBasket.totalPrice,
        totalProduct: newBasket.totalProduct,
        addressId: selectedAddress,
      })
      .then(() => {
        toastrService.message("Order was created", {
          messageType: "success",
          position: "bottom-right",
        });
      });

    localStorage.setItem("totalCartQuantity", 0);
    dispatch(updateCart({ quantity: 0 }));
    navigate("/profile/orders");
  };

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  useEffect(() => {
    if (basket.basketItems.length > 0) {
      const totalQuantity = basket.basketItems.reduce((total, item) => total + item.quantity, 0);
      localStorage.setItem("totalCartQuantity", totalQuantity)
      dispatch(updateCart({ quantity: totalQuantity }))
    } else {
      localStorage.setItem("totalCartQuantity", 0)
      dispatch(updateCart({ quantity: 0 }))
    }
  }, [basket]);

  return (
    <div className="cart-container">
      <Navbar />
      <Paper
        elevation={3}
        style={{ padding: 20, maxWidth: 800, margin: "10px 0", backgroundColor: 'rgba(167, 167, 167, 0.278)', color: 'whitesmoke' }}
      >
        <Typography variant="h4" gutterBottom align="center" color='whitesmoke'>
          Your Cart
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className="table-row">
                <TableCell align="center" style={{ color: 'whitesmoke' }}>Item</TableCell>
                <TableCell align="center" style={{ color: 'whitesmoke' }}>Quantity</TableCell>
                <TableCell align="center" style={{ color: 'whitesmoke' }}>Price</TableCell>
                <TableCell align="center" style={{ color: 'whitesmoke' }}>Total</TableCell>
                <TableCell align="center" style={{ color: 'whitesmoke' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(basket.basketItems) && basket.basketItems.length > 0 ? (
                basket.basketItems.map((item, index) => (
                  <TableRow key={index} className="table-item">
                    <TableCell style={{ color: 'whitesmoke' }}>
                      <Typography
                        style={{
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        variant="h6"
                      >
                        {item.name}
                      </Typography>
                      <Carousel className="cart-image-carosuel" autoPlay={false}>
                        <img
                          key={index}
                          src={
                            BASE_IMG_URL + item.frontImagePath ||
                            "../../public/logo.png"
                          }
                          alt={""}
                          style={{
                            width: "250px",
                            height: "200px",
                            objectFit: "contain",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            marginTop: "25px",
                          }}
                        />
                        <img
                          key={index}
                          src={
                            BASE_IMG_URL + item.backImagePath ||
                            "../../public/logo.png"
                          }
                          alt={""}
                          style={{
                            width: "250px",
                            height: "200px",
                            objectFit: "contain",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            marginTop: "25px",
                          }}
                        />
                      </Carousel>
                    </TableCell>
                    <TableCell align="right" className="quantity" style={{ color: 'whitesmoke' }}>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          updateQuantity(item.basketItemId, item.quantity - 1)
                        }
                        disabled={item.quantity <= 1}
                        style={{color: "whitesmoke", borderColor:'whitesmoke'}}
                      >
                        <RemoveIcon />
                      </Button>
                      <Typography
                        variant="body1"
                        style={{ display: "inline", margin: "0 10px", color: 'whitesmoke' }}
                      >
                        {item.quantity}
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          updateQuantity(item.basketItemId, item.quantity + 1)
                        }
                        style={{color: "whitesmoke", borderColor:'whitesmoke'}}
                      >
                        <AddIcon />
                      </Button>
                    </TableCell>
                    <TableCell align="right" className="single-price" style={{ color: 'whitesmoke' }}>
                      <p className="mobile-title">Item Price:</p>$
                      {item.price.toFixed(2)}
                    </TableCell>
                    <TableCell align="right" className="total-item-price" style={{ color: 'whitesmoke' }}>
                      <p className="mobile-title">Total Price:</p>$
                      {(item.quantity * item.price).toFixed(2)}
                    </TableCell>
                    <TableCell align="right" className="remove-item" style={{ color: 'whitesmoke' }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => removeItem(item.basketItemId)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center" style={{ color: 'whitesmoke' }}>
                    <Typography variant="body1">
                      No items in the basket
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>


        {basket.basketItems.length > 0 && (
          <>
            <div className="address-selection">
              <Typography variant="h6" gutterBottom>
                Choose Your Address
              </Typography>
              <Select
                value={selectedAddress}
                onChange={handleAddressChange}
                fullWidth
                variant="outlined"
                sx={{
                  marginBottom: '20px',
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                }}
                
              >
                {basket.addresses.length > 0 ? (
                  basket.addresses.map((address) => (
                    <MenuItem key={address.addressId} value={address.addressId}>
                      {address.title}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>
                    You don't have any registered address, please add a address.
                  </MenuItem>
                )}
              </Select>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/profile/addresses");
                }}
                style={{ marginBottom: 20, backgroundColor: "rgb(230, 109, 65)" }}
              >
                Go to My Addresses
              </Button>
            </div>
            <Typography variant="h5" align="right" style={{ marginTop: 20 }}>
              Total: ${calculateTotal()}
            </Typography>
            <div className="button-container">
              <Button
                variant="contained"
                color="primary"
                className="complete-button"
                align="left"
                onClick={handleCompletePurchase}
                style={{backgroundColor: "rgb(230, 109, 65)"}}
              >
                Complete Purchase
              </Button>
            </div>
          </>
        )}
      </Paper>
    </div>
  );
}
export default Cart;
