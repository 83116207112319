import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AuthService from "../../services/models/authService";
import RoleService from "../../services/models/roleService";

const AuthSettings = () => {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [otherRoles, setOtherRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [dialogData, setDialogData] = useState({
    open: false,
    action: "",
    roleId: "",
    roleName: "",
  });

  useEffect(() => {
    getAllRoles();
  }, []);

  const authService = new AuthService();
  const roleService = new RoleService();

  const getAllRoles = async () =>
    await roleService.getRoles().then((res) => setAllRoles(res.items));

  const handleEmailChange = (event) => setEmail(event.target.value);

  const handleSearch = async () => {
    try {
      await getUserData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAssignRole = () => {
    setDialogData((prev) => ({
      ...prev,
      open: true,
      action: "assign",
      roleId: getRoleIdByName(dialogData.roleName),
    }));
  };

  const handleRemoveRole = (roleName) => {
    setDialogData({
      open: true,
      action: "remove",
      roleId: getRoleIdByName(roleName),
      roleName: roleName,
    });
  };

  const getRoleIdByName = (name) => {
    const role = allRoles.find((role) => role.name === name);
    return role ? role.id : null;
  };

  const handleConfirm = async () => {
    try {
      if (dialogData.action === "assign")
        await authService.assignRole(user.id, dialogData.roleId);
      else if (dialogData.action === "remove")
        await authService.removeUserRole(user.id, dialogData.roleId);
      else if (dialogData.action === "ban") await authService.banUser(user.id);
      else if (dialogData.action === "unban")
        await authService.removeUserBan(user.id);

      await getUserData();

      setDialogData({ open: false, action: "", roleId: "", roleName: "" });
    } catch (error) {
      console.error(error);
    }
  };

  const getUserData = async () => {
    const response = await roleService.getUserRoles(email).then((res) => {
      setUserRoles(res.roles);
      setUser({ id: res.userId, isBanned: res.isBanned });
      return res;
    });

    determineUnassignedRoles(response);
  };

  const determineUnassignedRoles = (userRoles) => {
    const userRoleNames = userRoles.roles.map((role) => role);
    const filteredRoles = allRoles.filter(
      (role) => !userRoleNames.includes(role.name)
    );
    setOtherRoles(filteredRoles);
  };

  const handleBanUser = () => {
    setDialogData({
      open: true,
      action: "ban",
      roleId: "",
      roleName: "",
    });
  };

  const handleRemoveBan = () => {
    setDialogData({
      open: true,
      action: "unban",
      roleId: "",
      roleName: "",
    });
  };

  const handleDialogClose = () => {
    setDialogData({ open: false, action: "", roleId: "", roleName: "" });
  };

  return (
    <Box p={2}>
      <TextField
        label="Enter Email"
        variant="outlined"
        value={email}
        onChange={handleEmailChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button variant="contained" onClick={handleSearch} sx={{ mb: 2 }}>
        Search
      </Button>
      {user && <hr />}
      {user && (
        <Box mt={2}>
          <Typography variant="h6">Roles:</Typography>
          {Array.isArray(userRoles) && userRoles.length > 0 ? (
            userRoles.map((role) => (
              <Box key={role} display="flex" alignItems="center" mb={1}>
                <Typography variant="body1" sx={{ mr: 2 }}>
                  {role}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleRemoveRole(role)}
                  sx={{
                    color: "red",
                    borderColor: "red",
                    "&:hover": { borderColor: "darkred", color: "darkred" },
                  }}
                >
                  Remove Role
                </Button>
              </Box>
            ))
          ) : (
            <Typography variant="body1">No roles assigned</Typography>
          )}

          <Box mt={2} display="flex" alignItems="center" gap={2}>
            <Select
              value={dialogData.roleName}
              onChange={(e) =>
                setDialogData((prev) => ({ ...prev, roleName: e.target.value }))
              }
              displayEmpty
              sx={{ mr: 2 }}
              disabled={otherRoles.length === 0}
            >
              <MenuItem value="" disabled>
                Select Role
              </MenuItem>
              {otherRoles.length > 0 ? (
                otherRoles.map((role) => (
                  <MenuItem key={role.id} value={role.name}>
                    {role.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No roles available
                </MenuItem>
              )}
            </Select>
            <Button
              variant="contained"
              onClick={handleAssignRole}
              disabled={!dialogData.roleName || otherRoles.length === 0}
            >
              Assign Role
            </Button>
          </Box>
          <hr />

          <Box mt={2}>
            <Typography variant="h6">Ban Status:</Typography>
            <Typography variant="body1">
              User is currently {user.isBanned ? "banned" : "not banned"}.
            </Typography>
          </Box>

          <Box mt={2} display="flex" gap={2}>
            <Button
              variant="contained"
              color="error"
              onClick={handleBanUser}
              disabled={user.isBanned}
            >
              Ban User
            </Button>
            <Button
              variant="contained"
              onClick={handleRemoveBan}
              disabled={!user.isBanned}
            >
              Remove Ban
            </Button>
          </Box>
        </Box>
      )}

      <Dialog open={dialogData.open} onClose={handleDialogClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure to{" "}
            {dialogData.action === "assign"
              ? "assign this role"
              : dialogData.action === "remove"
              ? "remove this role"
              : dialogData.action === "ban"
              ? "ban this user"
              : "remove ban from this user"}
            ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleConfirm} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AuthSettings;
