import React, { useState, useRef, useEffect } from "react";
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Transformer,
  Text,
} from "react-konva";
import useImage from "use-image";
import "../styles/design.css";
import { useParams } from "react-router-dom";
import DraftService from "../services/models/draftService";
import Navbar from "../components/Navbar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import DraftImageService from "../services/models/draftImageService";
import UserImageService from "../services/models/userImageService";
import StorageManager from "../services/common/storageManager";
import TokenDecoder from "../services/common/tokenDecoder";
import ProductService from "../services/models/productService";
import LinearProgress from "@mui/material/LinearProgress";
import EditIcon from "@mui/icons-material/Edit";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import TokenIcon from '@mui/icons-material/Token';
import { toastrService } from "../services/common/toasterService";

const BASE_IMG_URL = process.env.REACT_APP_IMAGES_BASE_URL;

//Canvas tshirt
const TShirtImage = ({ view, color }) => {
  const imagePath = `/${color}-${view}-tshirt.png`;
  const [image] = useImage(imagePath);
  return (
    <KonvaImage
      image={image}
      x={0}
      y={50}
      width={500}
      height={500}
      draggable={false}
    />
  );
};

//Upload Image Function
const UploadedImage = ({
  src,
  x,
  y,
  scaleX,
  scaleY,
  isSelected,
  onSelect,
  onChange,
}) => {
  const [image] = useImage(src);
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <KonvaImage
        image={image}
        x={x}
        y={y}
        scaleX={scaleX}
        scaleY={scaleY}
        draggable
        ref={shapeRef}
        onClick={onSelect}
        onTap={onSelect}
        onDragEnd={(e) => onChange(e.target.x(), e.target.y(), scaleX, scaleY)}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          const newScaleX = node.scaleX();
          const newScaleY = node.scaleY();
          onChange(node.x(), node.y(), newScaleX, newScaleY);
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

//Add Text Function
const EditableText = ({
  text,
  x,
  y,
  fontSize,
  fontFamily,
  fill,
  isSelected,
  onSelect,
  onChange,
}) => {
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Text
        text={text}
        x={x}
        y={y}
        fontSize={fontSize}
        fontFamily={fontFamily}
        fill={fill}
        draggable
        ref={shapeRef}
        onClick={onSelect}
        onTap={onSelect}
        onDragEnd={(e) =>
          onChange(e.target.x(), e.target.y(), fontSize, fontFamily, fill)
        }
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          onChange(
            node.x(),
            node.y(),
            node.fontSize(),
            node.fontFamily(),
            node.fill()
          );
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

const Design = () => {

  const [AIToken, setAIToken] = useState(100)
  const [prompt, setPrompt] = useState("");
  const [images, setImages] = useState({ front: [], back: [] });
  const [texts, setTexts] = useState({ front: [], back: [] });
  const [selectedId, setSelectedId] = useState(null);
  const [selectedText, setSelectedText] = useState("");
  const [selectedFontSize, setSelectedFontSize] = useState(24);
  const [selectedFontFamily, setSelectedFontFamily] = useState("Arial");
  const [selectedTextColor, setSelectedTextColor] = useState("#000000");
  const [view, setView] = useState("front");
  const [color, setColor] = useState("gray"); // Color state
  const stageRef = useRef();
  const [imageList, setImageList] = useState([
    "/skeleton.png",
    "/flower.png",
    "/sun.png",
    "/fox.png",
    "/eye.png",
  ]);
  const [selectedAIModel, setSelectedAIModel] = useState("")

  const base64Ref = useRef();

  //Covert Images to Base64 format
  const handleConvertImagesToBase64 = async (imageArray) => {
    const convertToBase64 = async (imageUrl) => {
      const blob = await fetch(imageUrl).then((res) => res.blob());
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    };

    const base64Images = await Promise.all(imageArray.map(convertToBase64));
    setImageList(base64Images);
  };

  const { id } = useParams();
  const strogeManager = new StorageManager();
  const token = strogeManager.getToken().token;
  const tokenDecoder = new TokenDecoder();
  const userId = tokenDecoder.getUserId(token);

  const draftService = new DraftService();
  const draftImageService = new DraftImageService();
  const userImageService = new UserImageService();
  const productService = new ProductService();

  useEffect(() => {
    const fetchData = async () => {
      await getDraft(id);
      await handleUpload();
    };

    fetchData();
  }, [id]);

  //Todo Stage'in görüntüsünü alırken texti almıyor
  const handleUpload = async () => {
    const draft = await getDraft(id);
    const userImages = await userImageService.getImagesByUserId(userId, 0, 50);

    if (userImages) {
      const images = userImages.items.map((item) => {
        return BASE_IMG_URL + item.path;
      });

      setImageList([...imageList, ...images]);
    }

    setImages(draft.images);
    setTexts(draft.texts);
    setImageReady(true);
  };

  //Downloading Design
  const handleDownload = async () => {
    const formData = new FormData();

    const downloadImage = (view, callback) => {
      setView(view);
      setTimeout(() => {
        const uri = stageRef.current.toDataURL({
          pixelRatio: 3,
          x: 0,
          y: 0,
          width: stageRef.current.width(),
          height: stageRef.current.height(),
        });
        const link = document.createElement("a");
        link.download = `${view}-${id}`;
        const blob = dataURLtoBlob(uri);
        formData.append("files", blob, `${view}-${id}`);
        callback();
      }, 500);
    };

    const dataURLtoBlob = (dataURL) => {
      const [header, data] = dataURL.split(",");
      const mime = header.match(/:(.*?);/)[1];
      const binary = atob(data);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: mime });
    };

    formData.append("draftId", id);

    downloadImage("front", () => {
      downloadImage("back", async () => {
        await draftImageService.addDraftImages(formData);
      });
    });
  };


  //Add Text Function
  const handleAddText = () => {
    const newText = {
      text: "New Text",
      x: 150,
      y: 150,
      fontSize: selectedFontSize,
      fontFamily: selectedFontFamily,
      fill: selectedTextColor,
      componentId: `text-${view}-${texts[view].length}`,
    };
    setTexts({
      ...texts,
      [view]: [...texts[view], newText],
    });
    setSelectedId(newText.componentId);
    setSelectedText(newText.text);
  };

  //Select objects in canvas
  const handleSelect = (componentId, content) => {
    setSelectedId(componentId);
    if (content !== undefined) {
      setSelectedText(content);
    }
  };

  //Unselect objects in canvas
  const handleUnselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
      setSelectedText("");
    }
  };

  const handleChange = (
    componentId,
    x,
    y,
    scaleX = 1,
    scaleY = 1,
    content = null,
    fontSize = null,
    fontFamily = null,
    fill = null
  ) => {
    if (componentId.startsWith("text")) {
      setTexts({
        ...texts,
        [view]: texts[view].map((txt) =>
          txt.componentId === componentId
            ? {
              ...txt,
              x,
              y,
              text: content || txt.text,
              fontSize: fontSize || txt.fontSize,
              fontFamily: fontFamily || txt.fontFamily,
              fill: fill || txt.fill,
            }
            : txt
        ),
      });
    } else {
      setImages({
        ...images,
        [view]: images[view].map((img) =>
          img.componentId === componentId
            ? { ...img, x, y, scaleX, scaleY }
            : img
        ),
      });
    }
  };

  const handleTextChange = (e) => {
    setSelectedText(e.target.value);
    setTexts({
      ...texts,
      [view]: texts[view].map((txt) =>
        txt.componentId === selectedId ? { ...txt, text: e.target.value } : txt
      ),
    });
  };

  const handleFontSizeChange = (e) => {
    setSelectedFontSize(parseInt(e.target.value, 10));
    setTexts({
      ...texts,
      [view]: texts[view].map((txt) =>
        txt.componentId === selectedId
          ? { ...txt, fontSize: parseInt(e.target.value, 10) }
          : txt
      ),
    });
  };

  const handleFontFamilyChange = (e) => {
    setSelectedFontFamily(e.target.value);
    setTexts({
      ...texts,
      [view]: texts[view].map((txt) =>
        txt.componentId === selectedId
          ? { ...txt, fontFamily: e.target.value }
          : txt
      ),
    });
  };

  const handleTextColorChange = (e) => {
    setSelectedTextColor(e.target.value);
    setTexts({
      ...texts,
      [view]: texts[view].map((txt) =>
        txt.componentId === selectedId ? { ...txt, fill: e.target.value } : txt
      ),
    });
  };

  const handleViewChange = (e) => {
    setView(e.target.value);
  };

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  async function getDraft(id) {
    const response = await draftService.getDraftById(id);
    //console.log("response: ", response);

    const fetchedImages = {
      front: response.imageComponents.filter((image) => image.isFrontComponent),
      back: response.imageComponents.filter((image) => !image.isFrontComponent),
    };

    const fetchedTexts = {
      front: response.textComponents.filter((text) => text.isFrontComponent),
      back: response.textComponents.filter((text) => !text.isFrontComponent),
    };

    setTitle(response.title);
    setSize(response.size);
    setIsPrivate(response.isPrivate);

    console.log(response);

    return {
      images: fetchedImages,
      texts: fetchedTexts,
    };
  }

  const handleSaveDesign = async ({ title, size, isPrivate }) => {
    //console.log("images: ", images);
    const imgResult = [
      ...processItems(images.front, id, true),
      ...processItems(images.back, id, false),
    ];

    //console.log("texts ", texts);
    const txtResult = [
      ...processItems(texts.front, id, true),
      ...processItems(texts.back, id, false),
    ];

    const request = {
      id: id,
      title: title,
      size: size,
      isPrivate: isPrivate,
      imageComponents: imgResult,
      textComponents: txtResult,
    };

    await draftService.updateDraft(request);
  };

  const [open, setOpen] = useState(false);
  const [openFinish, setOpenFinish] = useState(false);
  const [title, setTitle] = useState("");
  const [size, setSize] = useState("M");
  const [isPrivate, setIsPrivate] = useState(false);

  const handleClickOpen = async () => {
    await getDraft(id);
    setOpen(true);
  };

  const handleClickOpenFinish = async () => {
    await getDraft(id);
    setOpenFinish(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenFinish(false);
  };

  const handleSave = () => {
    handleDownload();

    handleSaveDesign({ title, size, isPrivate });
    setOpen(false);
  };

  const handleFinish = async () => {
    await productService.addProductImages({
      draftId: id,
      name: title,
      price: 100,
      isPrivate: isPrivate,
    });

    setOpenFinish(false);
  };

  const processItems = (items, id, isFrontComponent) => {
    return items.map((item) => ({
      ...item,
      draftId: id,
      isFrontComponent,
    }));
  };

  const [generateActive, setGenerateActive] = useState(false);

  const [imageReady, setImageReady] = useState(false);

  const generateImage = async () => {
    setGenerateActive(true);
    const response = await userImageService.generateImage(prompt, userId);

    const image = response.map((item) => {
      return BASE_IMG_URL + item.path;
    });

    //console.log(image);
    setImageList([...imageList, image]);
    setImageReady(true);
    setGenerateActive(false);
  };

  useEffect(() => {
    console.log("useEffect worked");
    if (imageReady) {
      base64Ref.current.click();
      setImageReady(false);
    }
  }, [imageReady]);

  const [uploading, setUploading] = useState(false);

  const handleImageUpload = async (e) => {
    setUploading(true);
    const file = e.target.files[0];
    const reader = new FileReader();

    const formData = new FormData();
    formData.append("files", file);
    formData.append("userId", userId);

    await userImageService.uploadImage(formData);

    reader.onload = () => {
      const newImage = {
        src: reader.result,
        x: 100,
        y: 100,
        scaleX: 0.5,
        scaleY: 0.5,
        componentId: `${view}-${images[view].length}`,
      };
      setImages({
        ...images,
        [view]: [...images[view], newImage],
      });

      setImageList([...imageList, reader.result]);
    };
    reader.readAsDataURL(file);
    setUploading(false);
  };

  const handleImageUploadRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const src = e.dataTransfer.getData("text/plain");

    if (src) {
      setImages({
        ...images,
        [view]: [
          ...images[view],
          {
            src,
            x: 150,
            y: 150,
            scaleX: 0.5,
            scaleY: 0.5,
            componentId: `image-${view}-${images[view].length}`,
          },
        ],
      });
    }
  };

  const handleDoubleClick = async (src) => {
    if (src) {
      setImages({
        ...images,
        [view]: [
          ...images[view],
          {
            src,
            x: 150,
            y: 150,
            scaleX: 0.5,
            scaleY: 0.5,
            componentId: `image-${view}-${images[view].length}`,
          },
        ],
      });
    }
  };

  const [touchStart, setTouchStart] = useState(0);

  const handleTouchStart = () => {
    setTouchStart(Date.now());
  };

  const handleTouchEnd = (src) => {
    const touchDuration = Date.now() - touchStart;

    // If the touch duration is more than 500ms, treat it as a long press
    if (touchDuration > 500) {
      handleDoubleClick(src);
    }
  };

  const handleDeleteImage = (componentId) => {
    setImages({
      ...images,
      [view]: images[view].filter((img) => img.componentId !== componentId),
    });

    setTexts({
      ...texts,
      [view]: texts[view].filter((txt) => txt.componentId !== componentId),
    });

    setSelectedId(null);
  };

  const handleMobileDeleteImage = (componentId) => {
    setImages({
      ...images,
      [view]: images[view].filter((img) => img.componentId !== componentId),
    });

    setTexts({
      ...texts,
      [view]: texts[view].filter((txt) => txt.componentId !== componentId),
    });

    setSelectedId(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Backspace" && selectedId !== null) {
        handleDeleteImage(selectedId);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedId]);

  const controlsRef = useRef();

  const infosRef = useRef();

  const showControls = () => {
    controlsRef.current.classList.toggle("active-controls");
  };

  const showInfos = () => {
    infosRef.current.classList.toggle("active-tips");
  };

  const handleAIModelChange = (e) => {
    setSelectedAIModel(e.target.value);
  };

  return (
    <div className="app">
      <Navbar />
      <div className="main-container">
        <div className="tips" ref={infosRef}>
          <h3>Tool Tips</h3>
          <Accordion style={{ backgroundColor: '#ffffff3c', color: 'whitesmoke' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Uploading Image
            </AccordionSummary>
            <AccordionDetails>
              You can upload your own images to the canvas using the Upload
              Image button
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: '#ffffff3c', color: 'whitesmoke' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Generating Image
            </AccordionSummary>
            <AccordionDetails>
              You can generate image with AI by typing the description of the
              image you want in the input section under the Generate Image text.
              You can then use the image you produce in your product design.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: '#ffffff3c', color: 'whitesmoke' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Adding Text
            </AccordionSummary>
            <AccordionDetails>
              You can add any text you want to the canvas. You can change the
              text you added in the input section. You can also change the
              color, size and font family of the text. You have You must change
              the size of the text in the font size section. Otherwise the
              change will not be saved!
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: '#ffffff3c', color: 'whitesmoke' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              T-shirt Settings
            </AccordionSummary>
            <AccordionDetails>
              You can change the color of the t-shirt in the t-shirt settings
              section. You can choose the t-shirt size that suits you. You can
              choose which part of the t-shirt to apply the design to.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: '#ffffff3c', color: 'whitesmoke' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Saving Design
            </AccordionSummary>
            <AccordionDetails>
              If your design is interrupted and you want to continue later, or
              if there is a design you like and want to use later, you can save
              these designs.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: '#ffffff3c', color: 'whitesmoke' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Downloading Design
            </AccordionSummary>
            <AccordionDetails>
              You can download and save the design you created to your device.
              Be careful! Designs downloaded from the download design section
              cannot be uploaded to the system later for editing.
            </AccordionDetails>
          </Accordion>
        </div>

        <div
          className="canvas-container"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Stage
            className="stage"
            width={500}
            height={600}
            ref={stageRef}
            onClick={handleUnselect}
            onTap={handleUnselect}
          >
            <Layer>
              <TShirtImage
                view={view}
                color={color}
                className="canvas-tshirt"
              />
              {images[view].map((img) => (
                <UploadedImage
                  key={img.componentId}
                  src={img.src}
                  x={img.x}
                  y={img.y}
                  scaleX={img.scaleX}
                  scaleY={img.scaleY}
                  isSelected={img.componentId === selectedId}
                  onSelect={() => handleSelect(img.componentId)}
                  onChange={(x, y, scaleX, scaleY) =>
                    handleChange(img.componentId, x, y, scaleX, scaleY)
                  }
                />
              ))}
              {texts[view].map((txt) => (
                <EditableText
                  key={txt.componentId}
                  text={txt.text}
                  x={txt.x}
                  y={txt.y}
                  fontSize={txt.fontSize}
                  fontFamily={txt.fontFamily}
                  fill={txt.fill}
                  isSelected={txt.componentId === selectedId}
                  onSelect={() => handleSelect(txt.componentId, txt.text)}
                  onChange={(x, y, fontSize, fontFamily, fill) =>
                    handleChange(
                      txt.componentId,
                      x,
                      y,
                      null,
                      null,
                      txt.text,
                      fontSize,
                      fontFamily,
                      fill
                    )
                  }
                />
              ))}
            </Layer>
          </Stage>
        </div>

        <div className="controls" ref={controlsRef}>
          <div className="control-section">
            <h3>Image Settings</h3>
            <div className="upload-image">
              <button
                disabled={uploading}
                onClick={() => handleImageUploadRef.current.click()}
                className="upload-image-button"
              >
                {!uploading ? "Upload Image" : "Uploading"}
              </button>
              <button
                className="remove-image-button"
                onClick={() => handleMobileDeleteImage(selectedId)}
              >
                Remove
              </button>
              {uploading && (
                <LinearProgress style={{ marginTop: "10px" }} color="success" />
              )}
              <input
                type="file"
                accept="image/*"
                id="upload-image"
                style={{ display: "none" }}
                onChange={handleImageUpload}
                ref={handleImageUploadRef}
              />
            </div>

            <div className="generate-image">
              <h5>Generate Image</h5>
              <div className="ai-token"><p>Remaining Token</p><p>{AIToken}<TokenIcon /></p></div>
              <select
                className="ai-model-select"
                value={selectedAIModel}
                onChange={handleAIModelChange}
              >
                <option value="Arial">Dall-e 2 / 1 token</option>
                <option value="Courier New">Dall-e 3 / 5 token</option>
                <option value="Georgia">Adobe Firefly / 10 token</option>
              </select>
              <input type="text" value={prompt} onChange={handlePromptChange} />
              <button
                disabled={generateActive}
                className="generate-image-button"
                onClick={generateImage}
              >
                {!generateActive ? "Generate" : "Generating Image"}
              </button>
              {generateActive && <LinearProgress color="success" />}
            </div>
            <div className="images">
              {imageList.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`draggable-img-${index}`}
                  draggable
                  onDragStart={(e) => e.dataTransfer.setData("text/plain", src)}
                  onDoubleClick={() => handleDoubleClick(src)}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={() => handleTouchEnd(src)} // For mobile long-press
                />
              ))}
            </div>
          </div>

          <div className="control-section" id="handleBase64">
            <button
              ref={base64Ref}
              onClick={() => handleConvertImagesToBase64(imageList)}
            >
              Conver to base64
            </button>
          </div>

          <div className="control-section">
            <h3>Text Settings</h3>
            <button onClick={handleAddText} className="upload-image-button">
              Add Text
            </button>
            <input
              type="text"
              value={selectedText}
              onChange={handleTextChange}
            />
            <input
              type="color"
              value={selectedTextColor}
              onChange={handleTextColorChange}
            />
            <input
              type="number"
              value={selectedFontSize}
              onChange={handleFontSizeChange}
            />
            <select
              value={selectedFontFamily}
              onChange={handleFontFamilyChange}
            >
              <option value="Arial">Arial</option>
              <option value="Courier New">Courier New</option>
              <option value="Georgia">Georgia</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Verdana">Verdana</option>
            </select>
          </div>
          <div className="control-section">
            <h3>T-shirt Settings</h3>
            <h5>T-shirt Color</h5>
            <select value={color} onChange={handleColorChange}>
              <option value="black">Black</option>
              <option value="white">White</option>
              <option value="gray">Gray</option>
              <option value="red">Red</option>
              <option value="yellow">Yellow</option>
              <option value="blue">Blue</option>
              <option value="orange">Orange</option>
            </select>
            <h5>T-shirt View</h5>
            <select value={view} onChange={handleViewChange}>
              <option value="front">Front View</option>
              <option value="back">Back View</option>
            </select>
          </div>
          <button onClick={handleClickOpen} className="upload-image-button">
            Save Design
          </button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Save Design</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Title"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel>Size</InputLabel>
                <Select
                  value={size ?? ""}
                  onChange={(e) => setSize(e.target.value)}
                >
                  <MenuItem value="XS">XS</MenuItem>
                  <MenuItem value="S">S</MenuItem>
                  <MenuItem value="M">M</MenuItem>
                  <MenuItem value="L">L</MenuItem>
                  <MenuItem value="XL">XL</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                  />
                }
                label="Only you can see your design (Make your design Private)"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <button
            onClick={handleClickOpenFinish}
            className="upload-image-button"
          >
            Finish Design
          </button>
          <Dialog open={openFinish} onClose={handleClose}>
            <DialogTitle>Finish Design</DialogTitle>
            <DialogContent>
              <Typography>
                Your design will be saved as a product, make sure your latest
                changes are saved. Are you sure you want to save the design as a
                product?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleFinish} color="primary">
                Finish
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <button className="toggle-controls" onClick={showControls}>
          <EditIcon />
        </button>
        <button className="toggle-infos" onClick={showInfos}>
          <QuestionMarkOutlinedIcon />
        </button>
      </div>
    </div>
  );
};

export default Design;
