import { HttpClientService, RequestParameters } from "../../services/common/httpClientSevice"; 

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const httpClient = new HttpClientService(baseUrl);

export default class OrderService{
  async getOrders(pageIndex,pageSize){
    const requestParameters= new RequestParameters("orders",null,`PageIndex=${pageIndex}&PageSize=${pageSize}`);
    return await httpClient.get(requestParameters);
  }

  async getUserOrders(userId,pageIndex,pageSize){
    const requestParameters= new RequestParameters(`orders`,"GetByUserId",`PageIndex=${pageIndex}&PageSize=${pageSize}`);
    return await httpClient.get(requestParameters,userId);
  }

  async createOrder(order){
    const requestParameters= new RequestParameters("orders",);
    return await httpClient.post(requestParameters,order);
  }
}